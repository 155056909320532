// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {GeoPositionSource} from "@models/GeoPositionSource";
import {DasClientMemoryStorage} from "@services/das-client/das-client-storage";

export const environment = {
    production: false,
    authUrl: 'https://authstage.azurewebsites.net',
    applicationUrl: 'https://auth-stg.leader.knorr-bremse.com',
    gpsSimulatorUrl: 'https://das-stage.westeurope.cloudapp.azure.com/das',
    dasClientStorageType: DasClientMemoryStorage,

    /**
     * this setting skips showing the login page.
     * this also leads to the charts not displaying anything as we don't send any drive information to the server.
     */
    skipLogin: false,
    testMode: false,
    enableLogging: true,

    /**
     * Setting this option to true allows changing of external data sources,
     * which includes endpoint URLs and geo position source.
     * When set to true, external data sources must be set before accessing any other application page.
     */
    allowConfigurationOfExternalDataSources: false,

    /**
     * This setting determines the source for geo position.
     * For available values, see {@link GeoPositionSource}.
     */
    geoPositionSource: GeoPositionSource.Device,
    speedHistoryLength: 750,

    /**
     * Version of the application to be used in the UI
     */
    applicationVersion: "0.9.10",

    /**
     * Whether telemetry is enabled on startup.
     */
    telemetryEnabled: false,

    /**
     * URL for sending telemetry data via websocket.
     */
    // websocketFileLoggerUrl: 'wss://itt.diloc.de:8787'
    websocketFileLoggerUrl: undefined

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
