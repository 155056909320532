import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {LoggerService} from "@services/log/logger.service";
import {WebsocketFileLoggerService} from "@services/data/websocket-file-logger.service";
import {
    TrainService,
} from "@models/httpResponses";
import {PhysicalParameters} from "@models/PhysicalParameters";
import {HttpClient} from "@angular/common/http";
import {EndpointsService} from "@services/endpoints/endpoints.service";
import {DasClientProvisioningService} from "@services/das-client/das-client-provisioning.service";
import {translateErrorMessage} from "@util/DasClientErrorHelpers";

@Injectable({
    providedIn: 'root'
})
export class TrainInfoService {
    private defaultTrainHeadCode = '';
    private defaultTrainServiceList = [];
    private defaultPhysicalParameters = {
        mass: 1500,
        length: 500,
        numberOfLocos: 1
    };

    private trainHeadCode$ = new BehaviorSubject<string>(this.defaultTrainHeadCode);
    private trainService$ = new BehaviorSubject<TrainService>(undefined);
    private trainServiceList$ = new BehaviorSubject<TrainService[]>(this.defaultTrainServiceList);
    private physicalParameters$ = new BehaviorSubject<PhysicalParameters>(this.defaultPhysicalParameters);

    constructor(private dasClientProvisioningService: DasClientProvisioningService,
                private httpClient: HttpClient,
                private endpointsService: EndpointsService,
                private loggerService: LoggerService,
                private websocketFileLoggerService: WebsocketFileLoggerService) {
    }

    /**
     * Clears all train info
     */
    clear() {
        this.trainHeadCode$.next(this.defaultTrainHeadCode);
        this.clearExceptTrainHeadCode();
    }

    /**
     * Clears all train info except the train head code
     */
    clearExceptTrainHeadCode() {
        this.trainService$.next(undefined);
        this.trainServiceList$.next(this.defaultTrainServiceList);
        this.physicalParameters$.next(this.defaultPhysicalParameters);
    }

    /**
     * Getter for the train head code observable
     *
     * @return Observable<string> Emits with the current train head code.
     */
    public getTrainHeadCode(): Observable<string> {
        return this.trainHeadCode$.asObservable();
    }

    /**
     * Getter for the train service observable.
     *
     * @return Observable<TrainService> Emits with the current train service.
     */
    public getTrainService(): Observable<TrainService> {
        return this.trainService$.asObservable();
    }

    /**
     * Setter for the train head code.
     *
     * @param trainHeadCode The train head code.
     */
    public setTrainHeadCode(trainHeadCode: string): void {
        this.trainHeadCode$.next(trainHeadCode);
        this.websocketFileLoggerService.setIdentifier(trainHeadCode);
    }

    /**
     * Gets the current train head code
     */
    public getCurrentTrainHeadCode(): string {
        return this.trainHeadCode$.value;
    }

    /**
     * Gets the available train services for the currently set head code
     */
    public getAvailableTrainServicesForCurrentHeadCode(): TrainService[] {
        return this.trainServiceList$.value;
    }

    /**
     * Sets the available train services for the currently set head code
     *
     * @param trainServiceList list of available train services
     */
    public setAvailableTrainServicesForCurrentHeadCode(trainServiceList: TrainService[]) {
        this.trainServiceList$.next(trainServiceList);
    }

    /**
     * Getter for the physical parameters observable
     */
    public getPhysicalParameters() {
        return this.physicalParameters$.asObservable();
    }

    /**
     * Sets the physical parameters without sending them to the server
     *
     * @param mass The total mass of the whole train consist in tons
     * @param length The total length of the whole train consist in meters
     * @param numberOfLocos The number of locomotives in the consist.
     */
    public setPhysicalParametersLocally(mass: number, length: number, numberOfLocos: number) {
        this.physicalParameters$.next({mass, length, numberOfLocos});
    }

    /**
     * Try to receive the train service list.
     *
     * @param trainHeadCode
     * @return Observable which emits the train service list if successful or an error otherwise
     */
    public async getTrainServiceListForHeadCode(trainHeadCode: string): Promise<TrainService[]> {
        try {
            return await this.dasClientProvisioningService.getDasClient().listTrainService(trainHeadCode);
        } catch (err) {
            this.loggerService.log(this.constructor.name, `Error during listTrainService(${trainHeadCode})`, err);
            throw new Error(translateErrorMessage(err));
        }
    }

    /**
     * Try to select the train service.
     *
     * @param selectedTrainService
     * @return Observable which emits after selecting the train server if successful or throws an error otherwise
     */
    public async selectTrainService(selectedTrainService: TrainService) {
        try {
            await this.dasClientProvisioningService.getDasClient().selectTrainService(selectedTrainService, true);
        } catch (err) {
            this.loggerService.log(this.constructor.name, `Error during selectTrainService(${selectedTrainService})`, err);
            throw new Error(translateErrorMessage(err));
        }
    }

    /**
     * Sets the physical parameters of a train consist
     *
     * @param mass The total mass of the whole train consist in tons
     * @param length The total length of the whole train consist in meters
     * @param numberOfLocos The number of locomotives in the consist.
     */
    public async setPhysicalParameters(mass: number, length: number, numberOfLocos: number) {
        const massKg = mass * 1000;
        try {
            await this.dasClientProvisioningService.getDasClient().setPhysicalParameters(massKg, length, numberOfLocos);
        } catch (err) {
            this.loggerService.log(this.constructor.name, `Error during setPhysicalParameters(${massKg}, ${length}, ${numberOfLocos})`, err);
            throw new Error(translateErrorMessage(err));
        }
    }

    /**
     * Continues an unfinished run.
     *
     * @param trainService The train service of the run.
     */
    async continueUnfinishedRun(trainService: TrainService) {
        try {
            await this.dasClientProvisioningService.getDasClient().selectTrainService(trainService, false);
            this.trainService$.next(trainService);
            this.trainHeadCode$.next(trainService.headCodeShort);
        } catch (err) {
            this.loggerService.log(this.constructor.name, 'Error during continuing of an unfinished run', err);
            throw new Error(translateErrorMessage(err));
        }
    }
}
