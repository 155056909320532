import {Injectable} from '@angular/core';
import {HubConnection} from '@microsoft/signalr';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';
import {AdviceMessage, RouteMessage} from '@models/routeMessageModels';
import {EtaMessage} from '@models/etaMessageModels';
import {LoggerService} from '@services/log/logger.service';
import {FarsightRearviewMessage} from '@models/farsightRearviewMessageModels';
import {DasOAdviceMessage} from '@models/dasOAdviceMessageModels';
import {LocationMessage} from '@models/locationMessageModels';
import {InformationMessageType} from '@models/infoMessagesModels';
import {WebsocketFileLoggerService} from "@services/data/websocket-file-logger.service";
import {LogOffMessage} from "@models/logOffMessageModels";
import {TriggerType} from "@models/adviceScreenStateModels";
import {GpsMessage} from "@models/gpsMessageModels";
import {GnssSource} from "@models/GnssSource";
import {RouteState} from "@models/RouteState";
import {OperationMode} from "@models/OperationMode";

@Injectable({
    providedIn: 'root'
})
export abstract class DasMessagingService {

    public connection: HubConnection | undefined;
    protected gpsMessage$ = new ReplaySubject<GpsMessage>(1);
    protected routeMessage$ = new ReplaySubject<RouteMessage>(1);
    protected downloadProgressPercentage$ = new ReplaySubject<number>(1);
    protected adviceMessage$ = new ReplaySubject<AdviceMessage>(1);
    protected currentEta$ = new BehaviorSubject<EtaMessage>({
        stationName: '',
        arrivalDateTime: undefined,
        departureDateTime: undefined,
        estimatedTimeOfArrival: undefined
    });
    protected farsightRearviewMessage$ = new ReplaySubject<FarsightRearviewMessage>(1);
    protected removeEbulaStateMessage$ = new Subject<void>();
    protected dasOAdviceMessage$ = new ReplaySubject<DasOAdviceMessage>(1);
    protected locationMessage$ = new Subject<LocationMessage>();
    protected locationHistory$ = new ReplaySubject<LocationMessage[]>(1);
    protected arrivalTimeChangeEnabled$ = new ReplaySubject<boolean>(1);
    protected driveThroughEnabledMessage$ = new Subject<boolean>();
    protected endOfRunEnabledEventMessage$ = new Subject<boolean>();
    protected logOffEventMessage$ = new Subject<LogOffMessage>();
    protected isOnline$ = new BehaviorSubject<boolean>(false);
    protected infoMessage$ = new Subject<InformationMessageType>();
    protected gpsLostEventMessage$ = new Subject<void>();
    protected unscheduledStopEventMessage$ = new Subject<void>();
    protected unknownRouteEventMessage$ = new Subject<void>();
    protected gnssSource$ = new ReplaySubject<GnssSource>(1);
    protected routeState$ = new ReplaySubject<RouteState>(1);
    protected gnssProviderError$ = new ReplaySubject<string>(1);
    protected physicalParametersChangeEnabled$ = new ReplaySubject<boolean>(1);
    protected operationMode$ = new ReplaySubject<OperationMode>(1);

    protected constructor(protected loggerService: LoggerService, protected websocketFileLoggerService: WebsocketFileLoggerService) {
    }

    /**
     * starts data acquisition
     */
    abstract startDataAcquisition();

    /**
     * Clear data which was received from the server.
     */
    public clear(): void {
        this.routeMessage$.next(null);
        this.clearDynamicRunDataExceptInfoAndErrorMessages();
        this.infoMessage$.next(null);
    }

    /**
     * Clear the advice message received from the server.
     */
    public clearAdviceMessage() {
        this.adviceMessage$.next({advisedRunProfile: [], speedBubbles: []});
    }

    /**
     * Clear all information received dynamically during a run.
     */
    public clearDynamicRunDataExceptInfoAndErrorMessages() {
        this.adviceMessage$.next({advisedRunProfile: [], speedBubbles: []});
        this.locationMessage$.next({location: 0, speed: 0});
        this.locationHistory$.next([]);
        this.currentEta$.next({
            stationName: '',
            arrivalDateTime: undefined,
            departureDateTime: undefined,
            estimatedTimeOfArrival: undefined
        });
        this.dasOAdviceMessage$.next(null);
    }

    public getGpsMessage(): Observable<GpsMessage> {
        return this.gpsMessage$.asObservable();
    }

    public getRouteMessage(): Observable<RouteMessage> {
        return this.routeMessage$.asObservable();
    }

    public getDownloadProgressPercentage(): Observable<number> {
        return this.downloadProgressPercentage$;
    }

    public getAdviceMessage(): Observable<AdviceMessage> {
        return this.adviceMessage$.asObservable();
    }

    public getCurrentEta(): Observable<EtaMessage> {
        return this.currentEta$.asObservable();
    }

    public getFarsightRearviewMessage(): Observable<FarsightRearviewMessage> {
        return this.farsightRearviewMessage$.asObservable();
    }

    public getRemoveEbulaStateMessage(): Observable<void> {
        return this.removeEbulaStateMessage$.asObservable();
    }

    public getDasOAdviceMessage(): Observable<DasOAdviceMessage> {
        return this.dasOAdviceMessage$.asObservable();
    }

    public getLocationMessage(): Observable<LocationMessage> {
        return this.locationMessage$.asObservable();
    }

    public getLocationHistory(): Observable<LocationMessage[]> {
        return this.locationHistory$.asObservable();
    }

    public getArrivalTimeChangeEnabled(): Observable<boolean> {
        return this.arrivalTimeChangeEnabled$;
    }

    public getIsDriveThroughEnabled(): Observable<boolean> {
        return this.driveThroughEnabledMessage$.asObservable();
    }

    public getIsEndOfRunEnabled(): Observable<boolean> {
        return this.endOfRunEnabledEventMessage$.asObservable();
    }

    public getLogOffEventMessage(): Observable<LogOffMessage> {
        return this.logOffEventMessage$.asObservable();
    }

    public getInfoMessage(): Observable<InformationMessageType> {
        return this.infoMessage$.asObservable();
    }

    public getIsOnline(): Observable<boolean> {
        return this.isOnline$.asObservable();
    }

    public getUnscheduledStopEventMessage(): Observable<void> {
        return this.unscheduledStopEventMessage$.asObservable();
    }

    public getGpsLostEventMessage(): Observable<void> {
        return this.gpsLostEventMessage$.asObservable();
    }

    public getUnknownRouteEventMessage(): Observable<void> {
        return this.unknownRouteEventMessage$.asObservable();
    }

    public getGnssSource(): Observable<GnssSource> {
        return this.gnssSource$;
    }

    public getRouteState(): Observable<RouteState> {
        return this.routeState$;
    }

    public getGnssProviderError(): Observable<string> {
        return this.gnssProviderError$;
    }

    public getPhysicalParametersChangeEnabled(): Observable<boolean> {
        return this.physicalParametersChangeEnabled$;
    }

    public getOperationMode(): Observable<OperationMode> {
        return this.operationMode$;
    }

    public abstract sendArrivalTimeChange(step: number);

    public abstract sendAdviceDisplayed(triggerType: TriggerType);

    public abstract sendAdviceHidden(triggerType: TriggerType);

    public abstract sendAdviceInactive(triggerType: TriggerType);

    public abstract sendDriveThrough();

    public abstract sendExit();

    public abstract sendOnSchedule();
}
