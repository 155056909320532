import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LoggedInGuard} from "./guards/logged-in/logged-in.guard";
import {EndpointsSetGuard} from "./guards/endpoints-set/endpoints-set.guard";
import {UnfinishedRunResolverService} from "@services/data/unfinished-run-resolver.service";
import {ConfigureExternalDataSourcesAllowedGuard} from "./guards/configure-external-data-sources-allowed/configure-external-data-sources-allowed.guard";
import {GeoPositionSourceSetGuard} from "./guards/geo-position-source-set/geo-position-source-set.guard";


const routes: Routes = [
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    {
        path: 'configure-external-data-sources',
        loadChildren: () => import('./pages/configure-external-data-sources/configure-external-data-sources.module').then(m => m.ConfigureExternalDataSourcesPageModule),
        canActivate: [ConfigureExternalDataSourcesAllowedGuard]
    },
    {
        // attention: this is closely coupled to the gnss provider error modal!
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
        canActivate: [EndpointsSetGuard, GeoPositionSourceSetGuard]
    },
    {
        path: 'run-selection',
        loadChildren: () => import('./pages/run-selection/run-selection.module').then(m => m.RunSelectionPageModule),
        canActivate: [EndpointsSetGuard, GeoPositionSourceSetGuard, LoggedInGuard],
        resolve: {
            trainService: UnfinishedRunResolverService
        }
    },
    {
        path: 'set-physical-parameters',
        loadChildren: () => import('./pages/set-physical-parameters/set-physical-parameters.module').then(m => m.SetPhysicalParametersPageModule),
        canActivate: [EndpointsSetGuard, GeoPositionSourceSetGuard, LoggedInGuard]
    },
    {
        path: 'drive-mode',
        loadChildren: () => import('./pages/drive-mode/drive-mode.module').then(m => m.DriveModePageModule),
        canActivate: [EndpointsSetGuard, GeoPositionSourceSetGuard, LoggedInGuard]
    },
    {
        path: 'train-selection',
        loadChildren: () => import('./pages/train-selection/train-selection.module').then(m => m.TrainSelectionPageModule),
        canActivate: [EndpointsSetGuard, GeoPositionSourceSetGuard, LoggedInGuard]
    },
    {
        path: 'train-service-selection',
        loadChildren: () => import('./pages/train-service-selection/train-service-selection.module').then(m => m.TrainServiceSelectionPageModule),
        canActivate: [EndpointsSetGuard, GeoPositionSourceSetGuard, LoggedInGuard]
    },
    {
        path: 'diagnostic',
        loadChildren: () => import('./pages/diagnostic/diagnostic.module').then(m => m.DiagnosticPageModule),
        canActivate: [EndpointsSetGuard, GeoPositionSourceSetGuard, LoggedInGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            paramsInheritanceStrategy: 'always'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
