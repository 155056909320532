import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {TrainService} from "@models/httpResponses";
import {Observable} from "rxjs";
import {LoggerService} from "@services/log/logger.service";
import {TrainInfoService} from "@services/data/train-info.service";
import {DasClientAuthService} from "@services/auth/das-client-auth.service";

@Injectable({
    providedIn: 'root'
})
export class UnfinishedRunResolverService implements Resolve<TrainService>{

    constructor(private dasClientAuthService: DasClientAuthService,
                private trainInfoService: TrainInfoService,
                private router: Router,
                private loggerService: LoggerService) {
    }

    /**
     * Checks if there is an unfinished run available and returns the train service in case
     * Otherwise it navigates to the train selection page for starting a new run
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TrainService> | Promise<TrainService> | TrainService {
        const trainService = this.dasClientAuthService.getTrainServiceItemForSelectedLoco();
        console.log('getTrainServiceItemForSelectedLoco', trainService);
        if (trainService) {
            return trainService;
        }

        this.router.navigate(['train-selection']).catch((err) => {
            this.loggerService.log(this.constructor.name, 'An error occurred while navigating to train selection page', err);
        });
        return null;
    }
}
