import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {DasClientAuthService} from "@services/auth/das-client-auth.service";
import {LoginState} from "@models/LoginState";
import {environment} from "@environment/environment";

@Injectable({
    providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
    constructor(private dasClientAuthService: DasClientAuthService, private router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.dasClientAuthService.getLoginState()
            .pipe(
                map((loginState) => this.checkLogin(loginState)),
                catchError(() => of(this.router.parseUrl('/login')))
            );
    }

    checkLogin(loginState: LoginState): true | UrlTree {
        if (environment.skipLogin || loginState === LoginState.LocoSelected) {
            return true;
        }
        return this.router.parseUrl('/login');
    }
}
