import {LocationHelper} from "@util/LocationHelper";
import {CloseLocations, LineChartValue, RangeColumnValue, StripLine, ZoomRange} from "@models/chartModels";
import {SpeedLimit} from "@models/routeMessageModels";

/**
 * Applies zoom to max range columns
 *
 * @param maxRangeColumns the max range columns that are not yet zoomed
 * @param zoomRanges the zoom ranges for the chart
 */

export const zoomMaxRangeColumns = (maxRangeColumns: RangeColumnValue[], zoomRanges: ZoomRange[]) => {
    if (!maxRangeColumns || !zoomRanges || zoomRanges.length === 0) {
        return maxRangeColumns;
    }
    const result = [];
    for (const maxRangeColumn of maxRangeColumns) {
        //first entry is always the current location and never zoomed
        const newMaxRangeColumn = maxRangeColumn;
        newMaxRangeColumn.low = zoomSingleLocationIfRequired(maxRangeColumn.low, zoomRanges);
        newMaxRangeColumn.high = zoomSingleLocationIfRequired(maxRangeColumn.high, zoomRanges);
        result.push(newMaxRangeColumn);
    }
    return result;
};
/**
 * Applies zoom to maps containing location to customer meter post translations
 *
 * @param locationToCustomerMeterPosts the map containing location to customer meter post translations
 * @param zoomRanges the zoom ranges for the chart
 */

export const zoomCustomerMeterPosts = (locationToCustomerMeterPosts: Map<number, number>, zoomRanges: ZoomRange []): Map<number, number> => {
    if (!locationToCustomerMeterPosts || !zoomRanges || zoomRanges.length === 0) {
        return locationToCustomerMeterPosts;
    }
     const zoomedLocationToCustomerMeterPosts = new Map<number, number>();
    for (const locationData of locationToCustomerMeterPosts.keys()) {
        const zoomedLocationData = zoomSingleLocationIfRequired(locationData, zoomRanges);
        zoomedLocationToCustomerMeterPosts.set(zoomedLocationData, locationToCustomerMeterPosts.get(locationData));
    }
    return zoomedLocationToCustomerMeterPosts;
};

/**
 * Applies zoom to speed advice section markers
 *
 * @param speedAdviceSectionMarkers the speed advice section markers that are not yet zoomed
 * @param zoomRanges  the zoom ranges for the chart
 */

export const zoomSpeedAdviceSectionMarkers = (speedAdviceSectionMarkers: StripLine[], zoomRanges: ZoomRange []): StripLine [] => {
    if (!speedAdviceSectionMarkers || !zoomRanges || zoomRanges.length === 0) {
        return speedAdviceSectionMarkers;
    }
    const result = [];
    for (const speedAdviceSectionMarker of speedAdviceSectionMarkers) {
        const newSectionMarker = speedAdviceSectionMarker;
        newSectionMarker.start = zoomSingleLocationIfRequired(speedAdviceSectionMarker.start, zoomRanges);
        newSectionMarker.end = zoomSingleLocationIfRequired(speedAdviceSectionMarker.end, zoomRanges);
        result.push(newSectionMarker);
    }
    return result;
};

/**
 * Applies zoom to speed advice chart data
 *
 * @param speedAdviceChartData the speed advice chart data that has not yet been zoomed
 * @param zoomRanges the zoom ranges for the chart
 */

export const zoomSpeedAdviceChartData = (speedAdviceChartData: LineChartValue[], zoomRanges: ZoomRange []): LineChartValue[] => {
    if (!speedAdviceChartData || !zoomRanges || zoomRanges.length === 0) {
        return speedAdviceChartData;
    }
    for (const speedAdviceChartDataPoint of speedAdviceChartData) {
        speedAdviceChartDataPoint.y = zoomSingleLocationIfRequired(speedAdviceChartDataPoint.y, zoomRanges);
    }
    return speedAdviceChartData;
};


/**
 * Generates a yellow area with the charts. This yellow box marks the chart area in which the data way zoomed.
 *
 * @param sectionMarkers already used section makers
 * @param zoomRanges zoom ranges for the current run
 * @param currentLocation current location
 * @param endLocation max location that is currently displayed in the charts
 * @param useTextLabel whether or not a textLabel should be written to the section marker
 * @param zoomRangeBorderBufferPx a buffer for the zoom area. Will be subtracted from the overall area.
 * This buffer is needed to ensure that the section markers that represent the maximum speed changes are still visible
 */

export const addZoomAreasToSectionMarkers = (sectionMarkers, zoomRanges, startLocation, currentLocation, endLocation, useTextLabel, zoomRangeBorderBufferPx) => {
    for (const zoomRange of zoomRanges) {
        //we need to add a little buffer to the area otherwise it'll overlay the other stripline
        const startZoomArea = zoomRange.start + zoomRangeBorderBufferPx;
        let endZoomArea = zoomRange.start + zoomRange.rangeAfterZoom - zoomRangeBorderBufferPx;
        let textLabel = "";
        if (useTextLabel) {
            textLabel = LocationHelper.convertMetersPerSecondToKilometersPerHour(zoomRange.valueStartLocation).toString();
        }

        // If zoom area is completely outside the displayed area we don't have to do anything
        if (endZoomArea > startLocation && startZoomArea < endLocation) {
            if (currentLocation > startZoomArea) {
                textLabel = "";
            }
            ;
            if (endLocation < endZoomArea) {
                endZoomArea = endLocation;
            }
            const zoomArea: StripLine =
                {
                    color: "#FFFF8C",
                    start: startZoomArea,
                    end: endZoomArea,
                    visible: true,
                    horizontalAlignment: "End",
                    verticalAlignment: "End",
                    text: textLabel,
                    textStyle: {size: "20"},
                    border: {width: 2},
                    dashArray: "0",
                    sizeType: "Auto",
                    size: 400
                };
            sectionMarkers.push(zoomArea);
        }
    }
    ;
    sectionMarkers.sort(function (a, b) {
        return a.start - b.start;
    });
    return sectionMarkers;
}

/***
 * This function zooms a single location
 * @param location location that should be zoomed
 * @param zoomRanges all zoom ranges that can affect the location that should be zoomed
 */

export const zoomSingleLocationIfRequired = (location: number, zoomRanges: ZoomRange []) => {
    if (!zoomRanges || zoomRanges.length < 1) {
        return location;
    }
    for (const zoomRange of zoomRanges) {
        if (location <= zoomRange.start) {
            location = location;
        } else if (location < zoomRange.end) {
            const zoomFactor = zoomRange.rangeAfterZoom / zoomRange.rangeBeforeZoom;
            location = Math.round((location - zoomRange.start) * zoomFactor + zoomRange.start);
        } else {
            location = location + (zoomRange.rangeAfterZoom - zoomRange.rangeBeforeZoom);
        }
    }
    return location;
};


/**
 * determines which speed limits are too close to each other to be properly displayed
 * @param speedLimits the speed limits for the chart
 * @param minimalDistanceBetweenSpeedLimits the minimal distance between two speedlimits
 */

export const  determineCloseSpeedLimits = (speedLimits: SpeedLimit[], minimalDistanceBetweenSpeedLimits): CloseLocations[] => {
    if (!speedLimits || speedLimits.length < 2) {
        return [];
    }
    const speedLimitsThatAreTooClose = [];
    for (let i = 1; i < speedLimits.length; i++) {
        if (speedLimits[i].start - speedLimits[i - 1].start < minimalDistanceBetweenSpeedLimits) {
            const speedLimitStartsTooClose = {
                first: speedLimits[i - 1].start,
                second: speedLimits[i].start,
                valueFirstLocation: speedLimits[i - 1].value,
            };
            speedLimitsThatAreTooClose.push(speedLimitStartsTooClose);
        }
    }
    return speedLimitsThatAreTooClose;
};


/**
 * This functions takes close location and determines zoom ranges so that the close location pairs a spread to a given distance
 * If there are multiple entries that are to close, the later ones have to be moved in accordance to previously done zooming
 * for example: Given two location pairs. The distance between the first pair is enlarged from 200 to 400. The 200 additional location units
 * need to be added to the location of the second pair since it was now moved by 200.
 *
 * @param closeSpeedLimits speedlimits that are considered too close to plot
 * @param distanceBetweenSpeedLimitsAfterZoom distance that speedlimits should have after zooming
 */
export const determineZoomRanges = (closeSpeedLimits: CloseLocations[], distanceBetweenSpeedLimitsAfterZoom): ZoomRange[] => {
    const zoomRanges = [];
    let accumulatedMovingDistance = 0;
    for (const closeSpeedLimit of closeSpeedLimits) {
        const startOfZoom = closeSpeedLimit.first +  accumulatedMovingDistance;
        const endOfZoom = closeSpeedLimit.second + accumulatedMovingDistance;
        const zoomRange: ZoomRange = {
            start: startOfZoom,
            end: endOfZoom,
            rangeBeforeZoom: endOfZoom - startOfZoom,
            rangeAfterZoom: distanceBetweenSpeedLimitsAfterZoom,
            valueStartLocation: closeSpeedLimit.valueFirstLocation
        };
        zoomRanges.push(zoomRange);
        accumulatedMovingDistance += distanceBetweenSpeedLimitsAfterZoom - (endOfZoom - startOfZoom);
    }
    return zoomRanges;
};
