export enum FarsightRearviewBlockState {
    Occupied = 'occupied',
    Empty = 'empty',
    Unused = 'unused'
}

export interface FarsightRearviewTrainState {
	averageSpeed: number;
	blocks: FarsightRearviewBlockState[];
	trainCategory: string;
    blockInfo?: number[];
}

export interface FarsightRearviewEndValidity {
    endValidity: Date;
}
