import {Inject, Injectable} from "@angular/core";

import {DasClient} from "@knorr-bremse/das-client";
import {EndpointsService} from "@services/endpoints/endpoints.service";
import {LoggerService} from "@services/log/logger.service";
import type {DasClientStorage} from "@services/das-client/das-client-storage";


@Injectable({
    providedIn: 'root'
})
export class DasClientProvisioningService {
    private dasClient: DasClient;

    constructor(private endpointsService: EndpointsService,
                @Inject('DasClientStorage') private dasClientStorage: DasClientStorage,
                private loggerService: LoggerService) {
    }

    public getDasClient(): DasClient {
        if (!this.endpointsService.areEndpointsSet()) {
            throw new Error('could not provide DasClient as endpoints are not set');
        }

        if (!this.dasClient) {
            const applicationUrl = this.endpointsService.getApplicationUrl();
            this.loggerService.log(this.constructor.name, `Initializing DasClient with base url ${applicationUrl}`);
            this.dasClient = DasClient.create(applicationUrl, this.dasClientStorage);
        }

        return this.dasClient;
    }
}
