import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {
    FarsightRearviewBlockState,
    FarsightRearviewEndValidity,
    FarsightRearviewTrainState
} from "@models/farsightRearviewModels";
import {DasMessagingService} from "./das-messaging.service";
import {LoggerService} from "@services/log/logger.service";

/**
 * Service for the farsight-rearview component.
 */
@Injectable({
	providedIn: 'root'
})
export class FarsightRearviewService {
    private rearviewSubject$ = new BehaviorSubject<FarsightRearviewTrainState>({
        trainCategory: undefined,
        averageSpeed: undefined,
        blocks: this.fillUpValues([]),
        blockInfo: []
    });
    private farsightSubject$ = new BehaviorSubject<FarsightRearviewTrainState>({
        trainCategory: undefined,
        averageSpeed: undefined,
        blocks: this.fillUpValues([]),
        blockInfo: []
    });
    private farsightRearviewEndValidity$ = new BehaviorSubject<FarsightRearviewEndValidity>({
        endValidity: undefined
    });

	constructor(private dasMessagingService: DasMessagingService, private loggerService: LoggerService) {
		this.dasMessagingService.getFarsightRearviewMessage().subscribe(farsightRearviewMessage => {
            this.loggerService.log(this.constructor.name, "Got FarsightRearview message from server", farsightRearviewMessage);

            if (!farsightRearviewMessage) {
                this.clear();
                return;
            }

            this.farsightSubject$.next({
                trainCategory: farsightRearviewMessage.farsightTrainData.trainCategory,
                blocks: this.fillUpValues(farsightRearviewMessage.farsightTrainData.blocks).slice().reverse(),
                averageSpeed: farsightRearviewMessage.farsightTrainData.averageSpeed,
                blockInfo: (farsightRearviewMessage.farsightTrainData.blockInfo.map(obj => obj.length))?.slice().reverse()

            });
            this.rearviewSubject$.next({
                trainCategory: farsightRearviewMessage.rearviewTrainData.trainCategory,
                blocks: this.fillUpValues(farsightRearviewMessage.rearviewTrainData.blocks),
                averageSpeed: farsightRearviewMessage.rearviewTrainData.averageSpeed,
                blockInfo: farsightRearviewMessage.rearviewTrainData.blockInfo.map(obj => obj.length)
            });
            this.farsightRearviewEndValidity$.next({endValidity: farsightRearviewMessage.endValidity});
		});
    }

    /**
     * Clear farsight and rearview data which was received from the server.
     */
    public clear(): void {
        this.loggerService.log(this.constructor.name, "Clearing FarsightRearview message");
        this.farsightSubject$.next({
            trainCategory: undefined,
            averageSpeed: undefined,
            blocks: this.fillUpValues([]),
            blockInfo: undefined
        });
        this.rearviewSubject$.next({
            trainCategory: undefined,
            averageSpeed: undefined,
            blocks: this.fillUpValues([]),
            blockInfo: undefined
        });
        this.farsightRearviewEndValidity$.next({endValidity: undefined});
    }

    /**
     * Adds missing data to the array if less than 5 values were submitted.
     *
     * @param data The data to be filled up.
     *
     * @return boolean[] Returns an array with 5 values
     * @private
     */
    private fillUpValues(data: boolean[]): FarsightRearviewBlockState[] {
		const farsightRearviewStates: FarsightRearviewBlockState[] = data.map(state => state ? FarsightRearviewBlockState.Occupied : FarsightRearviewBlockState.Empty);
		while (farsightRearviewStates.length < 5) {
			farsightRearviewStates.push(FarsightRearviewBlockState.Unused);
		}
		return farsightRearviewStates;
	}

    /**
     * Getter for the rearview data observable.
     */
    public getRearview(): Observable<FarsightRearviewTrainState> {
        return this.rearviewSubject$.asObservable();
    }

    /**
     * Getter for the farsight data observable.
     */
    public getFarsight(): Observable<FarsightRearviewTrainState> {
        return this.farsightSubject$.asObservable();
    }

    /**
     * Getter for the FarsightRearviewEndValidity data observable.
     */
    public getFarsightRearviewEndValidity(): Observable<FarsightRearviewEndValidity> {
        return this.farsightRearviewEndValidity$.asObservable();
    }

}
