export enum InformationMessageType {
    UnscheduledStop = 'UnscheduledStop',
    GpsLost = 'GpsLost',
    NoAdviceArea = 'NoAdviceArea',
    ScheduledStop = 'ScheduledStop',
    UnknownRoute = 'UnknownRoute',
    OnStation = 'OnStation',
    LeavingStation = 'LeavingStation',
    ApproachingStation = 'ApproachingStation',
    Stop = 'Stop'
}

export const InformationMessageText = {
    [InformationMessageType.UnscheduledStop]: 'Unscheduled Stop',
    [InformationMessageType.GpsLost]: 'GPS lost',
    [InformationMessageType.NoAdviceArea]: 'No Advice Area',
    [InformationMessageType.ScheduledStop]: 'Scheduled Stop',
    [InformationMessageType.UnknownRoute]: 'Unknown Route',
    [InformationMessageType.OnStation]: 'On Station',
    [InformationMessageType.ApproachingStation]: 'Approaching Station',
    [InformationMessageType.LeavingStation]: 'Leaving Station',
    [InformationMessageType.Stop]: 'Stop'
};
