import {Injectable} from '@angular/core';
import {AdviceMessage, RouteMessage} from '@models/routeMessageModels';
import {EtaMessage} from '@models/etaMessageModels';
import {FarsightRearviewMessage} from '@models/farsightRearviewMessageModels';
import {DasOAdviceMessage} from '@models/dasOAdviceMessageModels';
import {InformationMessageType} from "@models/infoMessagesModels";
import {DasMessagingService} from "./das-messaging.service";

import routeMessage from '../../../assets/data/routeMessage.json';
import adviceMessage from '../../../assets/data/adviceMessage.json';
import farsightRearviewMessage from '../../../assets/data/farsightrearviewmessage.json';
import etaMessage from '../../../assets/data/etaMessage.json';
import dasOAdviceMessage from '../../../assets/data/dasoadvicemessage.json';
import {LoggerService} from "@services/log/logger.service";
import {WebsocketFileLoggerService} from "@services/data/websocket-file-logger.service";


@Injectable({
    providedIn: 'root'
})
export class TestDataService extends DasMessagingService {
    private locationProgressionInterval = 1000;
    private initialRouteMessageHoldOffDuration = 5000;
    private generateInfoAndErrorMessages = true;
    private locationProgressionIntervalHandle;
    private initialRouteMessageHoldOffHandle;

    constructor(protected loggerService: LoggerService,
                protected websocketFileLoggerService: WebsocketFileLoggerService) {
        super(loggerService, websocketFileLoggerService);
        this.startDataAcquisition();
    }

    /**
     * Initializes a SignalR connection and sets up the listeners for the messages from the server.
     */
    startDataAcquisition() {
        if (this.locationProgressionIntervalHandle) {
            this.loggerService.log(this.constructor.name, 'fake data interval is already running, call method stop before starting a second time');
            return;
        }

        this.loggerService.log(this.constructor.name, 'starting publishing fake data');
        this.initialRouteMessageHoldOffHandle = setTimeout(() => {
            this.routeMessage$.next(routeMessage as RouteMessage);
        }, this.initialRouteMessageHoldOffDuration);

        this.adviceMessage$.next(adviceMessage as AdviceMessage);

        const actualEtaMessage = {
            stationName: etaMessage.stationName,
            arrivalDateTime: new Date(etaMessage.arrivalDateTime),
            departureDateTime: new Date(etaMessage.departureDateTime),
            estimatedTimeOfArrival: new Date(etaMessage.estimatedTimeOfArrival)

        };
        this.currentEta$.next(actualEtaMessage as EtaMessage);
        const dasOAdviceList = dasOAdviceMessage.dasOAdviceList;
        const actualDasOAdviceList = dasOAdviceList.map(dasOAdvice => ({
            customerMeterpost: dasOAdvice.customerMeterpost,
            earliestArrivalTime: new Date(dasOAdvice.earliestArrival),
            eta: new Date(dasOAdvice.eta),
            latestArrivalTime: new Date(dasOAdvice.latestArrival),
            location: dasOAdvice.location,
            reasonCode: dasOAdvice.reasonCode
        }));
        this.dasOAdviceMessage$.next({dasOAdviceList: actualDasOAdviceList} as DasOAdviceMessage);

        const actualFarsightRearviewMessage = {
            farsightTrainData: farsightRearviewMessage.farsightTrainData,
            rearviewTrainData: farsightRearviewMessage.rearviewTrainData,
            endValidity: new Date(farsightRearviewMessage.endValidity)
        };

        this.farsightRearviewMessage$.next(actualFarsightRearviewMessage as FarsightRearviewMessage);
        this.isOnline$.next(true);

        let currentLocation = 0;
        this.locationProgressionIntervalHandle = setInterval(() => {
            currentLocation += 20;
            const speed = 25 + Math.random() * 1.2;
            const locationMessage = {
                location: currentLocation,
                speed
            };
            this.locationMessage$.next(locationMessage);

            if (this.generateInfoAndErrorMessages) {
                if (currentLocation === 60) {
                    this.infoMessage$.next(InformationMessageType.UnscheduledStop);
                }
                if (currentLocation === 160) {
                    this.infoMessage$.next(null);
                }
            }

            if (currentLocation === 360) {
                this.driveThroughEnabledMessage$.next();
            }
        }, this.locationProgressionInterval);
    }

    stop(): void {
        clearTimeout(this.initialRouteMessageHoldOffHandle);
        clearInterval(this.locationProgressionIntervalHandle);
    }

    public sendArrivalTimeChange(step: number) {
        this.loggerService.log(this.constructor.name,
            'not sending arrival time as test mode is activated in environment, ' +
            'but here is the message: ', {step});
    }

    public sendAdviceDisplayed() {
        this.loggerService.log(this.constructor.name,
            'not sending advice displayed as test mode is activated in environment');
    }

    public sendAdviceHidden() {
        this.loggerService.log(this.constructor.name,
            'not sending advice hidden as test mode is activated in environment');
    }

    public sendAdviceInactive() {
        this.loggerService.log(this.constructor.name,
            'not sending advice inactive as test mode is activated in environment');
    }

    public sendDriveThrough() {
        this.loggerService.log(this.constructor.name,
            'not sending drive through as test mode is activated in environment');
    }

    public sendExit() {
        this.loggerService.log(this.constructor.name,
            'not sending exit as test mode is activated in environment');
    }

    public sendOnSchedule() {
        this.loggerService.log(this.constructor.name,
            'not sending on schedule as test mode is activated in environment');
    }
}
