import {HostListener, Injectable} from '@angular/core';
import {LoggerService} from "@services/log/logger.service";
import {DasMessagingService} from "@services/data/das-messaging.service";
import {TriggerType} from "@models/adviceScreenStateModels";


/**
 * This class receives visibility information from app components, the document:visibilitychange event
 * and via the window:message event.
 * The received visibility information is sent via the given signal r connection service.
 */
@Injectable({
    providedIn: 'root'
})
export class VisibilityService {
    /**
     * The document visibility reflects the visibility of the HTML document.
     * This might be a browser window or an iframe FAS is embedded in.
     * The document visibility can be set by sending a message via
     * * window.postMessage({message: 'VisibilityChanged', data: {isVisible: true}})
     * * window.postMessage({message: 'VisibilityChanged', data: {isVisible: false}})
     *
     * @private
     */
    private documentVisible = true;

    /**
     * The advice visibility reflects the visibility of advices.
     * This value is set internally whenever advices are not visible.
     *
     * @private
     */
    private adviceVisible = false;

    /**
     * The advice availability reflects whether a speed advice is available after the current location.
     * This value is set internally, when the displayed advices are determined.
     *
     * @private
     */
    private adviceAvailable = false;

    constructor(private dasMessagingService: DasMessagingService,
                private loggerService: LoggerService) {
        window.addEventListener('message', (event) => this.windowMessage(event));
        document.addEventListener('load', () => this.sendAdviceVisibilityMessage(TriggerType.Automatic));
    }

    /**
     * sends the current visibility state.
     *
     * @private
     */
    sendAdviceVisibilityMessage(triggerType: TriggerType) {
        this.loggerService.log(this.constructor.name,
            'Sending advice visibility message based on' +
            `documentVisible:${this.documentVisible}, adviceVisible:${this.adviceVisible}, adviceAvailable:${this.adviceAvailable}`);
        if (this.documentVisible && this.adviceVisible && this.adviceAvailable) {
            this.dasMessagingService.sendAdviceDisplayed(triggerType);
        } else if (!this.documentVisible) {
            this.dasMessagingService.sendAdviceInactive(triggerType);
        } else {
            this.dasMessagingService.sendAdviceHidden(triggerType);
        }
    }

    @HostListener('document:visibilitychange', ['$event'])
    private documentVisibilityChanged() {
        this.loggerService.log(this.constructor.name, `Received document:visibilitychange event with visibilityState:${document.visibilityState}`);
        this.updateDocumentVisible(document.visibilityState === 'visible');
    }

    @HostListener('window:message', ['$event'])
    private windowMessage(message) {
        // we should check the origin of the message event before handling it, see https://youtrack.cn-consult.eu/issue/DLITT-310
        this.loggerService.log(this.constructor.name, 'Received window:message event', message);
        if (message?.data?.message === 'VisibilityChanged') {
            this.updateDocumentVisible(!!message?.data?.data?.isVisible);
        }
    }

    public updateDocumentVisible(visible: boolean) {
        if (this.documentVisible === visible) {
            return;
        }

        this.documentVisible = visible;
        this.sendAdviceVisibilityMessage(TriggerType.Manual);
    }

    public updateAdviceVisible(visible: boolean, triggerType: TriggerType) {
        if (this.adviceVisible === visible) {
            return;
        }

        this.adviceVisible = visible;
        this.sendAdviceVisibilityMessage(triggerType);
    }

    public updateAdviceAvailable(isAvailable: boolean) {
        if (this.adviceAvailable === isAvailable) {
            return;
        }

        this.adviceAvailable = isAvailable;
        this.sendAdviceVisibilityMessage(TriggerType.Automatic);
    }
}
