import {Injectable} from '@angular/core';
import {AdviceMessage, RouteMessage} from '@models/routeMessageModels';
import {EtaMessage} from '@models/etaMessageModels';
import {FarsightRearviewMessage} from '@models/farsightRearviewMessageModels';
import {DasOAdviceMessage} from '@models/dasOAdviceMessageModels';
import {LocationMessage} from '@models/locationMessageModels';
import {InformationMessageType} from '@models/infoMessagesModels';
import {DasMessagingService} from "./das-messaging.service";
import {AdviceScreenState, ConnectionState, TriggerType as DasClientTriggerType} from "@knorr-bremse/das-client";
import {LogLevel} from "@models/LogLevel";
import {WebsocketFileLoggerService} from "@services/data/websocket-file-logger.service";
import {LoggerService} from "@services/log/logger.service";
import {DasClientProvisioningService} from "@services/das-client/das-client-provisioning.service";
import {RouteState} from "@models/RouteState";
import {TriggerType}  from "@models/adviceScreenStateModels";

const triggerTypeMapping = {
    [TriggerType.Manual] : DasClientTriggerType.Manual,
    [TriggerType.Automatic] : DasClientTriggerType.Automatic,
};

@Injectable({
    providedIn: 'root'
})
export class DasClientDasMessagingService extends DasMessagingService {
    constructor(protected dasClientProvisioningService: DasClientProvisioningService,
                protected loggerService: LoggerService,
                protected websocketFileLoggerService: WebsocketFileLoggerService) {
        super(loggerService, websocketFileLoggerService);
        this.startDataAcquisition();
    }

    /**
     * connects the event listeners with the observables provided by DasClient
     */
    startDataAcquisition() {
        this.dasClientProvisioningService.getDasClient().gps$.subscribe(message => {
            this.loggerService.log(this.constructor.name, 'GpsMessage received', message);
            this.websocketFileLoggerService.logIncoming('GpsMessage', message);
            this.gpsMessage$.next(message);
        });

        this.dasClientProvisioningService.getDasClient().advice$.subscribe(message => {
            this.loggerService.log(this.constructor.name, 'AdviceMessage received', message);
            this.websocketFileLoggerService.logIncoming('AdviceMessage', message);
            this.adviceMessage$.next(message);
        });

        this.dasClientProvisioningService.getDasClient().route$.subscribe(message => {
            this.loggerService.log(this.constructor.name, 'RouteMessage received', message);
            this.websocketFileLoggerService.logIncoming('RouteMessage', message);
            this.routeMessage$.next(message);
        });

        this.dasClientProvisioningService.getDasClient().downloadProgress$.subscribe(downloadProgressPercentage => {
            this.loggerService.log(this.constructor.name, 'downloadProgress received', downloadProgressPercentage);
            this.websocketFileLoggerService.logIncoming('downloadProgress', downloadProgressPercentage);
            this.downloadProgressPercentage$.next(downloadProgressPercentage);
        });

        this.dasClientProvisioningService.getDasClient().location$.subscribe(message => {
            this.loggerService.log(this.constructor.name, 'LocationMessage received', message);
            this.websocketFileLoggerService.logIncoming('LocationMessage', message);
            this.locationMessage$.next(message);
        });

        this.dasClientProvisioningService.getDasClient().locationHistory$.subscribe(message => {
            this.loggerService.log(this.constructor.name, 'LocationHistoryMessage received', message);
            this.websocketFileLoggerService.logIncoming('LocationHistoryMessage', message);
            this.locationHistory$.next(message);
        });

        this.dasClientProvisioningService.getDasClient().eta$.subscribe(message => {
            this.loggerService.log(this.constructor.name, 'EtaMessage received', message);
            this.websocketFileLoggerService.logIncoming('EtaMessage', message);
            this.currentEta$.next(message);
        });

        this.dasClientProvisioningService.getDasClient().farsightRearview$.subscribe(message => {
            this.loggerService.log(this.constructor.name, 'FarsightRearviewMessage received', message);
            this.websocketFileLoggerService.logIncoming('FarsightRearviewMessage', message);
            this.farsightRearviewMessage$.next(message);
        });

        this.dasClientProvisioningService.getDasClient().arrivalTimeChangeReset$.subscribe(message => {
            this.loggerService.log(this.constructor.name, 'ResetArrivalTimeChangeEventMessage received', message);
            this.websocketFileLoggerService.logIncoming('ResetArrivalTimeChangeEventMessage', message);
            this.removeEbulaStateMessage$.next();
        });

        this.dasClientProvisioningService.getDasClient().dasoAdvice$.subscribe(message => {
            this.loggerService.log(this.constructor.name, 'DasOAdviceMessage received', message);
            this.websocketFileLoggerService.logIncoming('DasOAdviceMessage', message);
            this.dasOAdviceMessage$.next(message);
        });

        this.dasClientProvisioningService.getDasClient().arrivalTimeChangeEnabled$.subscribe(isEnabled => {
            this.loggerService.log(this.constructor.name, 'arrivalTimeChangeEnabled received', isEnabled);
            this.websocketFileLoggerService.logIncoming('arrivalTimeChangeEnabled', isEnabled);
            this.arrivalTimeChangeEnabled$.next(isEnabled);
        });

        this.dasClientProvisioningService.getDasClient().driveThroughEnabled$.subscribe(isEnabled => {
            this.loggerService.log(this.constructor.name, 'DriveThroughEnabledEventMessage received', isEnabled);
            this.websocketFileLoggerService.logIncoming('DriveThroughEnabledEventMessage', isEnabled);
            this.driveThroughEnabledMessage$.next(isEnabled);
        });

        this.dasClientProvisioningService.getDasClient().endOfRunEnabled$.subscribe(isEnabled => {
            this.loggerService.log(this.constructor.name, 'EndOfRunEnabledEventMessage received', isEnabled);
            this.websocketFileLoggerService.logIncoming('EndOfRunEnabledEventMessage', isEnabled);
            this.endOfRunEnabledEventMessage$.next(isEnabled);
        });

        this.dasClientProvisioningService.getDasClient().logOff$.subscribe(message => {
            this.loggerService.log(this.constructor.name, 'LogOffEventMessage received', message);
            this.websocketFileLoggerService.logIncoming('LogOffEventMessage', message);
            this.logOffEventMessage$.next(message);
        });

        this.dasClientProvisioningService.getDasClient().connectionState$.subscribe(connectionState => {
            this.loggerService.log(this.constructor.name, 'ConnectionState received', connectionState);
            this.isOnline$.next(connectionState === ConnectionState.Connected);
        });

        this.dasClientProvisioningService.getDasClient().gnssSource$.subscribe(gnssSource => {
            this.loggerService.log(this.constructor.name, 'gnssSource received', gnssSource);
            this.gnssSource$.next(gnssSource);
        });

        this.dasClientProvisioningService.getDasClient().routeState$.subscribe((routeState: RouteState) => {
            this.loggerService.log(this.constructor.name, 'routeState received', routeState);
            this.routeState$.next(routeState);
            switch (routeState) {
                case RouteState.UnscheduledStop:
                    this.websocketFileLoggerService.logIncoming('routeState_UnscheduledStop', null);
                    this.unscheduledStopEventMessage$.next();
                    this.infoMessage$.next(InformationMessageType.UnscheduledStop);
                    break;
                case RouteState.GpsLost:
                    this.websocketFileLoggerService.logIncoming('routeState_GpsLost', null);
                    this.gpsLostEventMessage$.next();
                    this.infoMessage$.next(InformationMessageType.GpsLost);
                    break;
                case RouteState.NoAdviceArea:
                    this.websocketFileLoggerService.logIncoming('routeState_NoAdviceArea', null);
                    this.infoMessage$.next(InformationMessageType.NoAdviceArea);
                    break;
                case RouteState.UnknownRoute:
                    this.websocketFileLoggerService.logIncoming('routeState_UnknownRoute', null);
                    this.unknownRouteEventMessage$.next();
                    this.infoMessage$.next(InformationMessageType.UnknownRoute);
                    break;
                case RouteState.ScheduledStop:
                    this.websocketFileLoggerService.logIncoming('routeState_ScheduledStop', null);
                    this.infoMessage$.next(InformationMessageType.ScheduledStop);
                    break;
                case RouteState.ApproachingStation:
                    this.websocketFileLoggerService.logIncoming('routeState_ApproachingStation', null);
                    this.infoMessage$.next(InformationMessageType.ApproachingStation);
                    break;
                case RouteState.OnStation:
                    this.websocketFileLoggerService.logIncoming('routeState_OnStation', null);
                    this.infoMessage$.next(InformationMessageType.OnStation);
                    break;
                case RouteState.LeavingStation:
                    this.websocketFileLoggerService.logIncoming('routeState_LeavingStation', null);
                    this.infoMessage$.next(InformationMessageType.LeavingStation);
                    break;
                case RouteState.Stop:
                    this.websocketFileLoggerService.logIncoming('routeState_Stop', null);
                    this.infoMessage$.next(InformationMessageType.Stop);
                    break;
                case RouteState.OpenTrack:
                    this.websocketFileLoggerService.logIncoming('routeState_OpenTrack', null);
                    this.infoMessage$.next(null);
                    break;
                default:
                    this.loggerService.log(this.constructor.name, 'unknown route state received, ignoring', routeState, LogLevel.Info);
                    break;
            }
        });

        this.dasClientProvisioningService.getDasClient().gnssProviderError$.subscribe(gnssProviderError => {
            this.loggerService.log(this.constructor.name, 'gnssProviderError received', gnssProviderError);
            this.gnssProviderError$.next(gnssProviderError);
        });

        this.dasClientProvisioningService.getDasClient().physicalParametersChangeEnabled$.subscribe(isEnabled => {
            this.loggerService.log(this.constructor.name, 'physicalParametersChangeEnabled received', isEnabled);
            this.physicalParametersChangeEnabled$.next(isEnabled);
        });

        this.dasClientProvisioningService.getDasClient().operationMode$.subscribe(operationMode => {
            this.loggerService.log(this.constructor.name, 'OperationMode received', operationMode);
            this.operationMode$.next(operationMode);
        });
    }

    public sendArrivalTimeChange(step: number) {
        this.websocketFileLoggerService.logOutgoing('changeArrivalTime', {step});

        try {
            this.dasClientProvisioningService.getDasClient().changeArrivalTime(step);
        } catch (err) {
            this.loggerService.log(this.constructor.name, 'An error occurred when calling changeArrivalTime', {
                step,
                err
            }, LogLevel.Error);
        }
    }

    public sendAdviceDisplayed(triggerType: TriggerType) {
        this.sendAdviceScreenState(AdviceScreenState.AdviceVisible, triggerType);
    }

    public sendAdviceHidden(triggerType: TriggerType) {
        this.sendAdviceScreenState(AdviceScreenState.AdviceNotVisible, triggerType);
    }

    public sendAdviceInactive(triggerType: TriggerType) {
        this.sendAdviceScreenState(AdviceScreenState.Inactive, triggerType);
    }

    private sendAdviceScreenState(screenState: AdviceScreenState, triggerType: TriggerType) {
        this.websocketFileLoggerService.logOutgoing('adviceScreenState', {state: screenState, triggerType});
        try {
            this.dasClientProvisioningService.getDasClient().adviceScreenState(screenState, triggerTypeMapping[triggerType]);
        } catch (err) {
            this.loggerService.log(this.constructor.name, 'An error occurred when calling adviceScreenState',
                {state: screenState, triggerType, err}, LogLevel.Error);
        }
    }

    public sendDriveThrough() {
        this.websocketFileLoggerService.logOutgoing('driveThrough');

        try {
            this.dasClientProvisioningService.getDasClient().driveThrough();
        } catch (err) {
            this.loggerService.log(this.constructor.name, 'An error occurred when calling driveThrough', err, LogLevel.Error);
        }
    }

    public sendExit() {
        this.websocketFileLoggerService.logOutgoing('exit');

        try {
            this.dasClientProvisioningService.getDasClient().exit();
        } catch (err) {
            this.loggerService.log(this.constructor.name, 'An error occurred when calling exit', err, LogLevel.Error);
        }
    }

    public sendOnSchedule() {
        this.websocketFileLoggerService.logOutgoing('onSchedule');

        try {
            this.dasClientProvisioningService.getDasClient().onSchedule();
        } catch (err) {
            this.loggerService.log(this.constructor.name, 'An error occurred when calling onSchedule', err, LogLevel.Error);
        }
    }
}
