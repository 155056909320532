import {Injectable} from '@angular/core';
import {DasMessagingService} from "./das-messaging.service";
import {Observable, ReplaySubject} from "rxjs";
import {DasOAdviceItem} from "@models/dasOAdviceMessageModels";
import {LogicalPositionService} from "@services/data/logical-position.service";
import {LogicalPosition} from "@models/LogicalPosition";

@Injectable({
    providedIn: 'root'
})
export class DasOAdviceService {
    private dasOAdvice$ = new ReplaySubject<DasOAdviceItem>(1);
    private advices: DasOAdviceItem[];
    private lastActiveAdvice: DasOAdviceItem;
    private currentLocation: number;

    constructor(private dasMessagingService: DasMessagingService, private logicalPositionService: LogicalPositionService) {
        this.dasMessagingService.getDasOAdviceMessage()
            .subscribe(message => {
                if (!message) {
                    this.reset();
                    return;
                }
                this.handleAdvicesChanged(message.dasOAdviceList);
            });
        this.logicalPositionService.getCurrentLogicalPosition()
            .subscribe(logicalPosition => this.handleLogicalPositionChanged(logicalPosition));
    }

	reset() {
        this.advices = [];
        this.lastActiveAdvice = null;
        this.dasOAdvice$.next(null);
    }

    handleAdvicesChanged(advices: DasOAdviceItem[]) {
        this.advices = advices;
        this.checkIfTheActiveAdviceHasBeenChanged();
    }

    handleLogicalPositionChanged(logicalPosition: LogicalPosition) {
        this.currentLocation = logicalPosition.location;
        this.checkIfTheActiveAdviceHasBeenChanged();
    }

    checkIfTheActiveAdviceHasBeenChanged() {
        const nearestAdvice = this.findNearestAdviceAfterLocation(this.advices, this.currentLocation);
        if (this.lastActiveAdvice === undefined || this.lastActiveAdvice !== nearestAdvice) {
            this.lastActiveAdvice = nearestAdvice;
            this.dasOAdvice$.next(nearestAdvice);
        }
    }

	findNearestAdviceAfterLocation(advices: DasOAdviceItem[], location: number) {
		return advices?.find(advice => advice.location > location) || null;
	}

	getDasOAdvice(): Observable<DasOAdviceItem> {
		return this.dasOAdvice$.asObservable();
	}
}
