import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {LoginState} from '@models/LoginState';
import {DasMessagingService} from "@services/data/das-messaging.service";
import {LoggerService} from "@services/log/logger.service";
import {DasClientProvisioningService} from "@services/das-client/das-client-provisioning.service";
import {TrainServiceItem} from "@models/TrainServiceItem";
import {translateErrorMessage} from "@util/DasClientErrorHelpers";

/**
 * This service is responsible for authentication.
 */
@Injectable({
    providedIn: 'root'
})
export class DasClientAuthService {
    private loginState$ = new BehaviorSubject<LoginState>(LoginState.NotLoggedIn);
    private selectedLoco$ = new BehaviorSubject<string>('');
    private trainServiceItemForSelectedLoco: TrainServiceItem;

    constructor(private dasClientProvisioningService: DasClientProvisioningService,
                private dasMessagingService: DasMessagingService,
                private loggerService: LoggerService) {
    }

    public getLoginState(): Observable<LoginState> {
        return this.loginState$.asObservable();
    }

    public getSelectedLoco(): Observable<string> {
        return this.selectedLoco$.asObservable();
    }

    public getTrainServiceItemForSelectedLoco(): TrainServiceItem {
        return this.trainServiceItemForSelectedLoco;
    }

    /**
     * Performs an initial login with license key and customerId.
     *
     * @param licenseKey The customers license key.
     * @param customerId The id of the customer.
     * @param deviceName Optional device name.
     */
    public async login(licenseKey: string, customerId: string, deviceName?: string): Promise<void> {
        try {
            await this.dasClientProvisioningService.getDasClient().register(customerId, licenseKey, deviceName);
        } catch (err) {
            this.loggerService.log(this.constructor.name, 'Error during register', err);
            throw new Error(translateErrorMessage(err));
        }
        this.loginState$.next(LoginState.ClientRegistered);
    }


    /**
     * Reset the login state in order to be able to log in again.
     */
    public logoff() {
        try {
            this.dasMessagingService.sendExit();
        } catch (err) {
            this.loggerService.log(this.constructor.name, 'Error during sendExit');
        }

        this.loginState$.next(LoginState.ClientRegistered);
        this.trainServiceItemForSelectedLoco = null;
        this.selectedLoco$.next('');
    }

    /**
     * Try to select the given loco ID.
     *
     * @param locoId
     * @return Promise which is resolved after successfully selecting the loco ID, rejected otherwise.
     */
    public async selectLoco(locoId: string): Promise<void | Error> {
        let trainServiceItem;
        try {
            trainServiceItem = await this.dasClientProvisioningService.getDasClient().selectLoco(locoId);
        } catch (err) {
            this.loggerService.log(this.constructor.name, 'Error during selectLoco', err);
            throw new Error(translateErrorMessage(err));
        }

        this.trainServiceItemForSelectedLoco = trainServiceItem;
        this.loginState$.next(LoginState.LocoSelected);
        this.selectedLoco$.next(locoId);
    }
}
