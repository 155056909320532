import {LoggerService} from "@services/log/logger.service";
import {environment} from "@environment/environment";
import {DasClientDasMessagingService} from "@services/data/das-client-das-messaging.service";
import {TestDataService} from "@services/data/test-data.service";
import {WebsocketFileLoggerService} from "@services/data/websocket-file-logger.service";
import {DasClientProvisioningService} from "@services/das-client/das-client-provisioning.service";

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function dasMessagingServiceFactory(dasClientProvisioningService: DasClientProvisioningService,
                                           loggerService: LoggerService,
                                           websocketFileLoggerService: WebsocketFileLoggerService) {
    if (environment.testMode) {
        loggerService.log('dasMessagingServiceFactory', 'creating TestDataService');
        return new TestDataService(loggerService, websocketFileLoggerService);
    }
    loggerService.log('dasMessagingServiceFactory', 'creating DasClientDasMessagingService');
    return new DasClientDasMessagingService(dasClientProvisioningService, loggerService, websocketFileLoggerService);
}
