import {BehaviorSubject, Observable} from "rxjs";
import {Injectable} from '@angular/core';

import {DasMessagingService} from "./das-messaging.service";
import {LoggerService} from "@services/log/logger.service";
import {LogicalPosition} from "@models/LogicalPosition";

@Injectable({
    providedIn: 'root'
})
export class LogicalPositionService {
    private currentLogicalPosition$ = new BehaviorSubject<LogicalPosition>({location: null, speed: null});

    constructor(private dasMessagingService: DasMessagingService,
                private loggerService: LoggerService) {
        dasMessagingService.getLocationMessage().subscribe((message) => {
            if (!message) {
                return;
            }

            this.loggerService.log(this.constructor.name, 'Processing location message from DasMessagingService.', message);
            this.updateLogicalPosition(message as LogicalPosition);
        });
    }

    /**
     * retrieve the current logical position as an Observable
     */
    public getCurrentLogicalPosition(): Observable<LogicalPosition> {
        return this.currentLogicalPosition$.asObservable();
    }

    private updateLogicalPosition(logicalPosition: LogicalPosition) {
        this.currentLogicalPosition$.next(logicalPosition);
    }
}
