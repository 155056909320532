import {Injectable} from '@angular/core';
import {environment} from "@environment/environment";
import {LogLevel} from "@models/LogLevel";

/**
 * This service is responsible for all logging stuff.
 */
@Injectable({
	providedIn: 'root'
})
export class LoggerService {

	constructor() {
	}

	/**
	 * Logs a submitted message.
	 * TODO: Think about a changing the default log level to something else.
	 *
	 * @param instance The name of the instance which requests a log.
	 * @param msg The message to log.
	 * @param additionalData Additional data for the message. Defaults to null.
	 * @param logLevel The log level for the message. Defaults to Debug.
	 */
	public log(instance: string, msg: string, additionalData: any = null, logLevel: string = LogLevel.Debug) {
		if (environment.enableLogging) {
			const message = new Date().toLocaleString() + ": " + instance + ": " + msg;
			let logFunction;
			switch (logLevel) {
				case LogLevel.Error:
                    // eslint-disable-next-line no-console
                    logFunction = console.error;
                    break;
                case LogLevel.Warning:
                    // eslint-disable-next-line no-console
                    logFunction = console.warn;
                    break;
                case LogLevel.Info:
                    // eslint-disable-next-line no-console
                    logFunction = console.info;
                    break;
                default:
                    // eslint-disable-next-line no-console
                    logFunction = console.log;
                    break;
            }

            if (additionalData !== null) {
                logFunction(message, additionalData);
            } else {
                logFunction(message);
            }
        }
	}
}
