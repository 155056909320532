import {Injectable} from '@angular/core';
import {DasMessagingService} from './das-messaging.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {EbulaPositionNumberToEbulaPositionBlockState} from "@models/ebulaPositionModels";

@Injectable({
    providedIn: 'root'
})
export class EbulaPositionControlService {
    private ebulaPosition = 0;

    private ebulaPositionBlockState$ = new BehaviorSubject<boolean[]>(EbulaPositionNumberToEbulaPositionBlockState.get(this.ebulaPosition));
    private ebulaScheduled$ = new BehaviorSubject<boolean>(false);

    constructor(private dasMessagingService: DasMessagingService) {
        dasMessagingService.getRemoveEbulaStateMessage().subscribe(() => this.clear());

        dasMessagingService.getIsOnline().subscribe((isOnline) => {
            if (isOnline) {
                this.resendCurrentEbulaPosition();
            }
        });
    }

    /**
     * Lengthen the current ebula position by one step.
     */
    public lengthen() {
        this.updateEbulaPosition(this.ebulaPosition + 1);
    }

    /**
     * Shorten the current ebula position by one step.
     */
    public shorten() {
        this.updateEbulaPosition(this.ebulaPosition - 1);
    }

    /**
     * Schedule ebula information from the server.
     */
    public schedule() {
        this.dasMessagingService.sendOnSchedule();
        this.ebulaScheduled$.next(true);
    }

    /**
     * Reset the EbulaPositionControl without sending the updated ebula position to the server.
     */
    public reset() {
        this.ebulaPosition = 0;
        this.ebulaPositionBlockState$.next(EbulaPositionNumberToEbulaPositionBlockState.get(this.ebulaPosition));
        this.ebulaScheduled$.next(false);
    }

    /**
     * Clear the EbulaPositionControl and send the updated ebula position to the server.
     */
    public clear() {
        this.reset();
        this.sendEbulaPositionToServer();
    }

    /**
     * Getter for the EbulaPositionBlockState observable.
     */
    public getEbulaPositionBlockState(): Observable<boolean[]> {
        return this.ebulaPositionBlockState$.asObservable();
    }

    /**
     * Getter for the EbulaScheduled observable.
     */
    getEbulaScheduled(): Observable<boolean> {
        return this.ebulaScheduled$.asObservable();
    }

    /**
     * Set a new ebula position and send it to the server.
     * If the new position is out of bounds, no update is sent to the server.
     *
     * @param newPosition
     * @private
     */
    private updateEbulaPosition(newPosition: number) {
        if (newPosition < -3 || newPosition > 3) {
            return;
        }

        this.ebulaPosition = newPosition;
        this.ebulaPositionBlockState$.next(EbulaPositionNumberToEbulaPositionBlockState.get(this.ebulaPosition));
        this.sendEbulaPositionToServer();
    }

    /**
     * Send the ebula position to the server.
     *
     * @private
     */
    private sendEbulaPositionToServer() {
        this.dasMessagingService.sendArrivalTimeChange(this.ebulaPosition);
    }

    private resendCurrentEbulaPosition() {
        // according to Knorr, we need to reset before resending the current ebula state
        this.dasMessagingService.sendArrivalTimeChange(0);

        if (this.ebulaScheduled$.value) {
            this.dasMessagingService.sendOnSchedule();
        } else {
            this.sendEbulaPositionToServer();
        }
    }
}
