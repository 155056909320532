import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {YAxisLocationReferencePoints} from "@models/chartModels";
import {environment} from "@environment/environment";
import {LogicalPositionService} from "@services/data/logical-position.service";
import {ZoomRangeService} from "@services/data/zoom-range.service";
import {zoomSingleLocationIfRequired} from "@util/ZoomHelper";

@Injectable({
    providedIn: 'root'
})
export class YAxisLocationReferencePointsService {
    public yAxisLocationReferencePoints$ = new Subject<YAxisLocationReferencePoints>();
    private rangeDisplayedInChartsMeter = 5750;
    private zoomRanges = [];
    /**
     * The chart always contains a certain amount of history data and the upcoming advice data
     * The range for the history is given by a setting in the environment file
     * The overall range of data displayed is determined by rangeDisplayedInChartsMeter.
     * Also we alway have two locations: The original unzoomed location that is provided by the location service
     * and a zoomed location that is required by the charts and determines the span of the chart axis and the way
     * the data is updated.
     *
     * @private
     */

    private yAxisLocationReferencePoints: YAxisLocationReferencePoints = {
        startLocationZoomed: environment.speedHistoryLength * (-1),
        startLocationUnzoomed: environment.speedHistoryLength * (-1),
        currentLocationZoomed: 0,
        currentLocationUnzoomed: 0,
        endLocation: this.rangeDisplayedInChartsMeter - environment.speedHistoryLength,
    };

    constructor(private logicalPositionService: LogicalPositionService, private zoomRangeService: ZoomRangeService) {
        this.logicalPositionService.getCurrentLogicalPosition().subscribe(logicalPosition => {
            const zoomedLocation = zoomSingleLocationIfRequired(logicalPosition.location, this.zoomRanges);
            const locationDifferenceZoomed = zoomedLocation - this.yAxisLocationReferencePoints.currentLocationZoomed;
            const locationDifferenceUnzoomed = logicalPosition.location - this.yAxisLocationReferencePoints.currentLocationUnzoomed;
            this.yAxisLocationReferencePoints.startLocationZoomed += locationDifferenceZoomed;
            //we also need the unzoomed start location for all data lookups into the route messages have to be done with the unzoomed location
            this.yAxisLocationReferencePoints.startLocationUnzoomed += locationDifferenceUnzoomed;
            this.yAxisLocationReferencePoints.endLocation += locationDifferenceZoomed;
            this.yAxisLocationReferencePoints.currentLocationZoomed = zoomedLocation;
            this.yAxisLocationReferencePoints.currentLocationUnzoomed = logicalPosition.location;
            this.yAxisLocationReferencePoints$.next(this.yAxisLocationReferencePoints);
        });
        this.zoomRangeService.getOverallZoomRanges().subscribe(zoomRanges => {
            this.zoomRanges = zoomRanges;
        });
    }

    /**
     * Clear the y-Axis reference points.
     */
    public clear(): void {
        this.yAxisLocationReferencePoints$.next({
            startLocationZoomed: environment.speedHistoryLength * (-1),
            startLocationUnzoomed: environment.speedHistoryLength * (-1),
            currentLocationZoomed: 0,
            currentLocationUnzoomed: 0,
            endLocation: this.rangeDisplayedInChartsMeter - environment.speedHistoryLength,
        });
    }

    public getYAxisLocationReferencePoints(): Observable<YAxisLocationReferencePoints> {
        return this.yAxisLocationReferencePoints$.asObservable();
    }
}
