import {Injectable} from '@angular/core';
import {DasClientAuthService} from "@services/auth/das-client-auth.service";
import {Router} from "@angular/router";
import {LoggerService} from "@services/log/logger.service";
import {ClearService} from "@services/clear.service";

@Injectable({
    providedIn: 'root'
})
export class LogoffService {
    constructor(private router: Router,
                private dasClientAuthService: DasClientAuthService,
                private clearService: ClearService,
                private loggerService: LoggerService) {
    }

    /**
     * End the current session and return to the login screen.
     */
    public async logoff() {
        this.dasClientAuthService.logoff();
        this.clearService.clearAllRunData();

        this.router.navigate(["/login"]).catch((err) => {
            this.loggerService.log(this.constructor.name, 'An error occurred while navigating to login page', err);
        });
    }
}
