import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from "rxjs";
import {environment} from "@environment/environment";
import {FactualSpeed} from "@models/factualSpeedModels";
import {LocationHelper} from "@util/LocationHelper";
import {ChartHelper} from "@util/ChartHelper";
import {LogicalPositionService} from "@services/data/logical-position.service";
import {DasMessagingService} from "@services/data/das-messaging.service";
import {LocationMessage} from "@models/locationMessageModels";

const reduceLocationHistoryLengthTo = (locationHistory: LocationMessage[], currentLocation: number, maximumLocationHistoryLength: number) => {
    const smallestLocationRepresentedInHistory = currentLocation - maximumLocationHistoryLength;
    return locationHistory.filter(locationHistoryEntry => locationHistoryEntry.location >= smallestLocationRepresentedInHistory);
};

@Injectable({
    providedIn: 'root'
})

/**
 * This class provides the history data for the actually driven speeds
 */
export class FactualSpeedHistoryService {
    private factualSpeedHistory$ = new ReplaySubject<FactualSpeed[]>(1);
    private locationHistory: LocationMessage[] = [];

    constructor(logicalPositionService: LogicalPositionService,
                dasMessagingService: DasMessagingService) {
        dasMessagingService.getLocationHistory().subscribe(locationHistory => {
            this.locationHistory = locationHistory;
        });

        logicalPositionService.getCurrentLogicalPosition()
            .subscribe((logicalPosition) => {
                try {
                    const filteredLocationHistory = reduceLocationHistoryLengthTo(this.locationHistory, logicalPosition.location, environment.speedHistoryLength);
                    this.factualSpeedHistory$.next(filteredLocationHistory.map(locationHistoryElement => ({
                        location: locationHistoryElement.location,
                        drivenSpeed: LocationHelper.convertMetersPerSecondToKilometersPerHour(locationHistoryElement.speed, ChartHelper.speedValueChartPrecision),
                    })));
                } catch (err) {
                    console.error('Failed to calculate location history', err);
                }
            });
    }

    /**
     * Clear the factual speed history.
     */
    public clear(): void {
        this.factualSpeedHistory$.next([]);
    }

    /**
     * returns the factual speed history as an Observable
     */
    getFactualSpeedHistory(): Observable<FactualSpeed[]> {
        return this.factualSpeedHistory$.asObservable();
    }
}
