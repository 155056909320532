export enum GeoPositionSource {
    /**
     * This means that geo position is determined based on the device's gps information.
     */
    Device = "DEVICE",

    /**
     * This means that geo position is determined based on gps information received from the Knorr Bremse simulator.
     */
	Simulator = "SIMULATOR"
}
