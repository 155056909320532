import {LocationHelper} from "@util/LocationHelper";
import {LineChartValue, RangeColumnValue, StripLine, StripLineBorder} from "@models/chartModels";
import {Anchor, FontModel, SizeType} from "@syncfusion/ej2-angular-charts";
import {AdviceType} from "@models/routeMessageModels";

/**
 * Helper class to generate chart data.
 */
export class ChartHelper {
    static speedValueChartPrecision = 5;
    static speedValueLabelPrecision = 0;
    public static lineWidth = 5;

    public static createLineChartValue(location: number, speed: number, displayedSpeed: number, showSpeedLabel: boolean, color: string = "white"): LineChartValue {
        return {
            y: location,
            x: LocationHelper.convertMetersPerSecondToKilometersPerHour(speed, ChartHelper.speedValueChartPrecision),
            label: showSpeedLabel ? displayedSpeed.toFixed(ChartHelper.speedValueLabelPrecision) : '',
            showDataLabel: showSpeedLabel,
            color
        };
    }

    public static createRangeColumnValue(x, low, high, color = "black"): RangeColumnValue {
        const value = Math.round(x);
        return {x: value, low, high, color};
    }

    /**
     * Generates a max speed strip line.
     *
     * @param location The location of the speed line.
     * @param color The color for the strip line. Defaults to black.
     * @param visible The visibility of the strip line. Defaults to true.
     * @param text Label for the StripLine. Defaults to an empty string.
     */
    public static createStripLine(location: number, text: string = "", color = "black", visible: boolean = true) {
        return this.createStripLineValue(location, color, visible, text);
    };


    public static createSectionStripLine(location: number, color: string = "black", visible: boolean = true) {
        return this.createStripLineValue(location, color, visible, "", {width: 2}, "10");
    }

    /**
     * Creates a dotted strip line value for the submitted data.
     *
     * @param location coordinate that represents where the StripLine will be shown.
     * @param color The color for the strip line. Defaults to black.
     * @param visible The visibility of the strip line. Defaults to true.
     * @param text Contents of the text label for the StripLine.
     * @param border The border of the strip line.
     * @param dashArray Defines the pattern for the dashes in the dotted line.
     * @param size Thickness of the line.
     * @param sizeType Defines the unit in which the size is measured.
     * @param horizontalAlignment Defines in which horizontal alignment the text label is in regard to the line itself.
     * @param verticalAlignment Defines in which vertical alignment the text label is in regard to the line itself.
     * @param textStyle Takes an FontModel object with the property "size" that changes the font size of the text label.
     */
    public static createStripLineValue(location: number,
                                       color: string,
                                       visible: boolean,
                                       text: string,
                                       border: StripLineBorder = {width: 2},
                                       dashArray: string = "0",
                                       size: number = 2,
                                       sizeType: SizeType = "Pixel",
                                       horizontalAlignment: Anchor = "End",
                                       verticalAlignment: Anchor = "End",
                                       textStyle: FontModel = {size: "20"}): StripLine {
        return {
            color,
            start: location,
            end: location,
            visible,
            text,
            border,
            dashArray,
            size,
            sizeType,
            horizontalAlignment,
            verticalAlignment,
            textStyle
        };
    }

    /**
     * Returns a color for the speed advice line. This color depends on the the advice type and dark mode.
     *
     * @param adviceType The advice type.
     * @param darkMode Whether colors should be determined for dark mode.
     * @return string Returns the color for the submitted advice type.
     */
    public static getLineColor(adviceType: AdviceType, darkMode: boolean = false): string {
        switch (adviceType) {
            case AdviceType.PastAdvice:
                return darkMode ? '#818181FF' : '#A5A5A5FF';
            case AdviceType.NoAdvice:
                return darkMode ? '#FFFFFF00' : '#00000000';
            case AdviceType.SpeedKeeping:
                return darkMode ? '#D8D8D8FF' : '#414141FF';
            case AdviceType.Traction:
                return darkMode ? '#D8D8D8FF' : '#414141FF';
            case AdviceType.Coasting:
                return darkMode ? '#C27ACCFF' : '#CC70DAFF';
            case AdviceType.Brake:
                return darkMode ? '#C27ACCFF' : '#CC70DAFF';
            case AdviceType.FactualSpeed:
                return darkMode ? '#E5E5E5FF' : '#818181FF';
            default:
                return darkMode ? '#FFFF00FF' : '#FFFF00FF';
        }
    }


    /**
     * Returns a color for the topography line. This color depends on the  dark mode.
     *
     * @param darkMode Whether colors should be determined for dark mode.
     * @return string Returns the color for the submitted advice type.
     */
    public static getTopographyLineColor(darkMode: boolean = false): string {
        return darkMode ? '#4CAF50' : '#388E3C';
    }


    /**
     * Function that interpolates a x-value for a given location. Location data is stored in the y-property
     *
     * @param currentLocation the location for which the data should be interpolated
     * @param previousDataPoint the last seen datapoint before the currentLocation
     * @param dataPoint the datapoint that just surpassed the currentLocation
     */

    static interpolateXValueForLocation(currentLocation: number, previousDataPoint: LineChartValue, dataPoint: LineChartValue): number {
        const positionOfActualLocationRelativeToSmallestNearest: number = (currentLocation - previousDataPoint.y) / (dataPoint.y - previousDataPoint.y);
        const differenceInXValues: number = dataPoint.x - previousDataPoint.x;
        // noinspection UnnecessaryLocalVariableJS
        const interpolatedSpeed = previousDataPoint.x + positionOfActualLocationRelativeToSmallestNearest * differenceInXValues;
        return interpolatedSpeed;
    }

    /**
     * Function that interpolates a y-value for a given location. Location data is stored in the x-property
     *
     * @param currentLocation the location for which the data should be interpolated
     * @param previousDataPoint the last seen datapoint before the currentLocation
     * @param dataPoint the datapoint that just surpassed the currentLocation
     */
    static interpolateYValueForLocation(currentLocation: number, previousDataPoint: LineChartValue, dataPoint: LineChartValue): number {
        const positionOfActualLocationRelativeToSmallestNearest: number = (currentLocation - previousDataPoint.x) / (dataPoint.x - previousDataPoint.x);
        const differenceInYValues: number = dataPoint.y - previousDataPoint.y;
        // noinspection UnnecessaryLocalVariableJS
        const interpolatedSpeed = previousDataPoint.y + positionOfActualLocationRelativeToSmallestNearest * differenceInYValues;
        return interpolatedSpeed;
    }


    /**
     * If datapoint labels are too close to each other, sync fusion just plots one of them.
     * In order to avoid sync fusion accidentally removing the label,
     * we just remove one of the datapoints that is too close to another one
     *
     * @param data the lineChartData
     * @param minDistanceTooLabels distance between to datapoint labels
     */
    static removeDataPointsTooCloseToPlot(data: LineChartValue[], minDistanceTooLabels) {
        // we do need to check the first element since there is no previous element that could be removed
        for (let index = data.length - 1; index >= 0; index--) {
            const element = data[index];
            if (element && element.showDataLabel) {
                const amountOfElementsTooCloseToLabel = this.determineAmountOfDataPointsTooClose(data, index, element.y, minDistanceTooLabels);
                data.splice(index - amountOfElementsTooCloseToLabel, amountOfElementsTooCloseToLabel);
            }
        }
        return data;
    }

    static determineAmountOfDataPointsTooClose(data: LineChartValue[], elementIndex, elementYCoordinate, minDistance) {
        let i = 0;
        while (elementIndex - 1 - i >= 0 && data[elementIndex - i - 1] && Math.abs(elementYCoordinate - data[elementIndex - i - 1].y) <= minDistance) {
            i++;
        }
        return i;
    }

}
