import {Injectable} from '@angular/core';
import {DasMessagingService} from './das-messaging.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {ActiveDriveControl} from '@models/ActiveDriveControl';

@Injectable({
    providedIn: 'root'
})
export class DriveControlService {
    private activeDriveControl$ = new BehaviorSubject<ActiveDriveControl>(ActiveDriveControl.EbulaPosition);

    private isDriveThroughAvailable = false;
    private isEndRunAvailable = false;

    constructor(private dasMessagingService: DasMessagingService) {
        this.dasMessagingService.getIsDriveThroughEnabled().subscribe((isEnabled) => {
            this.isDriveThroughAvailable = isEnabled;
            this.updateActiveDriveControl();
        });
        this.dasMessagingService.getIsEndOfRunEnabled().subscribe((isEnabled) => {
            this.isEndRunAvailable = isEnabled;
            this.updateActiveDriveControl();
        });
    }

    public clear(): void {
        this.isDriveThroughAvailable = false;
        this.isEndRunAvailable = false;
        this.updateActiveDriveControl();
    }

    public getActiveDriveControl(): Observable<ActiveDriveControl> {
        return this.activeDriveControl$.asObservable();
    }

    public activateDriveThrough() {
        this.isDriveThroughAvailable = false;
        this.dasMessagingService.sendDriveThrough();
        this.updateActiveDriveControl();
    }

    private updateActiveDriveControl() {
        if (this.isEndRunAvailable) {
            this.activeDriveControl$.next(ActiveDriveControl.EndRun);
        } else if (this.isDriveThroughAvailable) {
            this.activeDriveControl$.next(ActiveDriveControl.DriveThrough);
        } else {
            this.activeDriveControl$.next(ActiveDriveControl.EbulaPosition);
        }
    }
}
