export interface RouteElement {
    location: number;
    latitude: number;
    longitude: number;
    altitude: number;
    gradient: number;
}

export interface LocationMarker {
    marker: string;
    location: number;
    content?: string;
    additionalProperties?: AdditionalProperty[];
}

export interface AdditionalProperty {
    [key: string]: string;
}

export interface SpeedLimit {
    start: number;
    end: number;
    value: number;
}

export interface RouteMessage {
    routeElements: RouteElement[];
    locationMarkers: LocationMarker[];
    speedLimits: SpeedLimit[];
}

export interface LocationElement {
    location: number;
    speed: number;
}

export interface AdvisedRunProfileElement extends LocationElement {
    adviceType: AdviceType;
    duration: number;
    isAdviceChanged: boolean;
}

export interface SpeedBubble extends LocationElement {
    displayedSpeed: number;
}

export interface AdviceMessage {
    advisedRunProfile: AdvisedRunProfileElement[];
    speedBubbles: SpeedBubble[];
}

export enum LocationMarkerType {
    KilometerPost = "kilometer_post",
    SectionEnd = "section_end",
    InterpolatedSectionStart = "interpolated_section_begin",
    InterpolatedSectionEnd = "interpolated_section_end"
}

export enum AdviceType {
    FactualSpeed = -2,
    PastAdvice = -1,
    NoAdvice = 0,
    SpeedKeeping = 1,
    Coasting = 2,
    Brake = 3,
    Traction = 4
}
