import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {
    GnssProviderErrorModalComponent
} from "@components/gnss-provider-error-modal/gnss-provider-error-modal.component";

@NgModule({
    declarations: [GnssProviderErrorModalComponent],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule
    ],
    exports: [GnssProviderErrorModalComponent]
})
export class GnssProviderErrorModalModule {
}
