import {Component, Input} from '@angular/core';
import { GnssProviderError } from '@models/GnssProviderError';


@Component({
    selector: 'das-gnss-provider-error-modal',
    templateUrl: './gnss-provider-error-modal.component.html',
    styleUrls: ['./gnss-provider-error-modal.component.scss'],
})
export class GnssProviderErrorModalComponent {
    @Input() gnssProviderError: string = null;

    public ReceiverDetached = GnssProviderError.ReceiverDetached;
    public Unavailable = GnssProviderError.Unavailable;

    constructor() {
    }
}
