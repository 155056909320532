import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {AppComponent} from './app.component';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {ReportRequestViaWebsocketInterceptor} from "./http-interceptors/report-request-via-websocket-interceptor";
import {dasMessagingServiceFactory} from "@services/data/das-messaging-service-factory";
import {DasMessagingService} from "@services/data/das-messaging.service";
import {LoggerService} from "@services/log/logger.service";
import {WebsocketFileLoggerService} from "@services/data/websocket-file-logger.service";
import {Network} from '@awesome-cordova-plugins/network/ngx';
import {DasClientProvisioningService} from "@services/das-client/das-client-provisioning.service";
import {GnssProviderErrorModalModule} from "@components/gnss-provider-error-modal/gnss-provider-error-modal.module";
import {environment} from "@environment/environment";

export const HttpLoaderFactory = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http, '/assets/i18n/', '.json');

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        GnssProviderErrorModalModule
    ],
    providers: [
        {
            provide: DasMessagingService,
            useFactory: dasMessagingServiceFactory,
            deps: [DasClientProvisioningService, LoggerService, WebsocketFileLoggerService]
        },
        {
            provide: 'DasClientStorage', useClass: environment.dasClientStorageType
        },
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: ReportRequestViaWebsocketInterceptor, multi: true},
        Network
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
