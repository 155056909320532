import {webSocket, WebSocketSubject} from "rxjs/webSocket";
import {Injectable} from '@angular/core';
import {environment} from "@environment/environment";
import {delay, retryWhen, tap} from "rxjs/operators";
import {LoggerService} from "@services/log/logger.service";
import {LogLevel} from "@models/LogLevel";
import {ReplaySubject, Subscription} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class WebsocketFileLoggerService {
    private ws: WebSocketSubject<any>;
    private identifier = null;
    private messageQueue: ReplaySubject<any> = new ReplaySubject<any>();
    private messageQueueSubscription: Subscription;
    private sendMessageEnabled = environment.telemetryEnabled;

    constructor(private loggerService: LoggerService) {
    }

    /**
     * Initiate the connection to the websocket server and log an initial startup message
     */
    start() {
        if (!environment.websocketFileLoggerUrl) {
            return;
        }

        this.ws = webSocket({
            url: environment.websocketFileLoggerUrl,
            openObserver: {next: event => this.handleOpen(event)}, // happens after connection is established
            closeObserver: {next: event => this.handleClose(event)} // happens on every reconnect-attempt
        });

        // configure reconnect on error.
        this.ws.pipe(
            retryWhen(errors =>
                errors.pipe(
                    tap(error => {
                        this.loggerService.log(this.constructor.name,
                            'An error occurred on the websocket logger connection', error, LogLevel.Error);
                    }),
                    delay(1000)
                )
            )
        ).subscribe();
        this.log('startup', {});
    }

    /**
     * Set the identifier for all messages to come.
     *
     * @param identifier An identifier in order to identify the device the log message came from.
     */
    setIdentifier(identifier: string) {
        this.identifier = identifier;
    }

    /**
     * Enable or disable sending of messages.
     *
     * @param enabled Whether sending should be enabled.
     */
    setTelemetryEnabled(enabled: boolean) {
        this.sendMessageEnabled = enabled;
    }

    /**
     * Queue a message for sending.
     *
     * @param methodName An identifier for the message to be logged.
     * @param message The message to be logged.
     */
    log(methodName: string, message: any) {
        if (!this.sendMessageEnabled) {
            return;
        }
        this.loggerService.log(this.constructor.name, `queued message ${methodName}`, message);
        this.messageQueue.next({identifier: this.identifier, name: methodName, content: message});
    }

    /**
     * Identical to {@link log}, but method name is prefixed with "incoming ".
     */
    logIncoming(methodName: string, message: any) {
        this.log(`incoming ${methodName}`, message);
    }

    /**
     * Identical to {@link log}, but method name is prefixed with "outgoing ".
     */
    logOutgoing(methodName: string, message: any = null) {
        this.log(`outgoing ${methodName}`, message);
    }

    /**
     * Handler for established connections.
     *
     * @private
     */
    private handleOpen(event: Event) {
        this.loggerService.log(this.constructor.name, 'connection established, subscribing to message queue', event);
        this.messageQueueSubscription = this.messageQueue.subscribe(message => {
            this.loggerService.log(this.constructor.name, 'sending message from queue', message);
            this.ws.next(message);
        });
    }

    /**
     * Handler for closed connections.
     *
     * @private
     */
    private handleClose(event: CloseEvent) {
        this.loggerService.log(this.constructor.name, 'connection closed, unsubscribing from message queue', event);
        if (this.messageQueueSubscription) {
            this.messageQueueSubscription.unsubscribe();
            // as there's no way of clearing the existing buffer, we create a new subject on disconnect
            this.messageQueue = new ReplaySubject<any>();
            this.messageQueueSubscription = null;
        }
    }
}
