import {Injectable} from '@angular/core';

import {environment} from "@environment/environment";
import {LoggerService} from "@services/log/logger.service";
import {GeoPositionSource} from "@models/GeoPositionSource";

/**
 * This service is responsible for providing the source of the geo position.
 */
@Injectable({
    providedIn: 'root'
})
export class GeoPositionSourceService {
    private defaultGeoPositionSource = environment.geoPositionSource;

    public geoPositionSource;

    constructor(private loggerService: LoggerService) {
    }

    /**
     * this functions checks whether the geo position source is set.
     * if configuration of external data sources is not allowed, this is always expected to be true.
     */
    public isGeoPositionSourceSet(): boolean {
        return !environment.allowConfigurationOfExternalDataSources || !!this.geoPositionSource;
    }

    public setGeoPositionSource(geoPositionSource: GeoPositionSource): void {
        if (!environment.allowConfigurationOfExternalDataSources) {
            this.loggerService.log(this.constructor.name, 'Changing geoPositionSource is disabled.');
            return;
        }

        this.geoPositionSource = geoPositionSource;
    }

    public getGeoPositionSource(): GeoPositionSource {
        return environment.allowConfigurationOfExternalDataSources && !!this.geoPositionSource ? this.geoPositionSource : this.defaultGeoPositionSource;
    }
}
