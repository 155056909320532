import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {WebsocketFileLoggerService} from "@services/data/websocket-file-logger.service";

@Injectable()
export class ReportRequestViaWebsocketInterceptor implements HttpInterceptor {
    constructor(private websocketFileLoggerService: WebsocketFileLoggerService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.websocketFileLoggerService.log(`${req.method} ${req.url}`, null);
        return next.handle(req);
    }
}
