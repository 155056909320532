export enum RouteState {
    /** The train is moving on open track. No message needs to be displayed. */
    OpenTrack = "OpenTrack",
    /** GPS data is not available. */
    GpsLost = "GpsLost",
    /** GPS data is available, but it cannot mapped to the route. */
    UnknownRoute = "UnknownRoute",
    /** The train is approaching the next station. */
    ApproachingStation = "ApproachingStation",
    /** The train is moving in a station area. */
    OnStation = "OnStation",
    /** The train is leaving a station. */
    LeavingStation = "LeavingStation",
    /** The train is moving in no advice area. */
    NoAdviceArea = "NoAdviceArea",
    /** The train is stopped in a station area. */
    ScheduledStop = "ScheduledStop",
    /** The train is stopped outside the station area. */
    UnscheduledStop = "UnscheduledStop",
    /** The train is stopped during DAS-O active. */
    DasOStop = "DasOStop",
    /** The train is stopped. */
    Stop = "Stop"
}
