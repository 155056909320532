import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {filter} from "rxjs/operators";

import {DasMessagingService} from "@services/data/das-messaging.service";
import {LoggerService} from "@services/log/logger.service";
import {DarkModeService} from "@services/data/dark-mode.service";
import {LogoffService} from "@services/logoff.service";
import {LogLevel} from "@models/LogLevel";
import {ClearService} from "@services/clear.service";
import {WebsocketFileLoggerService} from "@services/data/websocket-file-logger.service";
import {VisibilityService} from "@services/data/visibility.service";
import {GnssProviderErrorModalService} from "@services/data/gnss-provider-error-modal.service";


@Component({
    selector: 'das-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    private currentGnssProviderError: string = null;

    constructor(private router: Router,
                private translateService: TranslateService,
                private dasMessagingService: DasMessagingService,
                private loggerService: LoggerService,
                private darkModeService: DarkModeService,
                private logoffService: LogoffService,
                // ensure the ClearService is instantiated
                private clearService: ClearService,
                // ensure the VisibilityService is instantiated
                private visibilityService: VisibilityService,
                // ensure the GnssProviderErrorModalService is instantiated
                private gnssProviderErrorModalService: GnssProviderErrorModalService,
                private websocketFileLoggerService: WebsocketFileLoggerService) {
    }

    async ngOnInit() {
        this.websocketFileLoggerService.start();
        await this.initLanguage();

        this.dasMessagingService.getLogOffEventMessage().subscribe(async (logOffMessage) => {
            this.loggerService.log(this.constructor.name, 'Logging off due to LogOffEventMessage', logOffMessage, LogLevel.Info);
            await this.logoffService.logoff();
        });

        this.darkModeService.getDarkModeState().subscribe((darkModeEnabled) => {
            document.body.classList.toggle('dark', darkModeEnabled);
        });

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.checkGnssProviderErrorModal();
        });

        this.dasMessagingService.getGnssProviderError().subscribe(gnssProviderError => {
            this.currentGnssProviderError = gnssProviderError;
            this.checkGnssProviderErrorModal();
        });
    }

    private async checkGnssProviderErrorModal() {
        // the overlay should not be shown on the customer-login and loco-selection-form screens
        // attention: this is closely coupled to the app's routing configuration!
        if (this.router.url.includes('login')) {
            try {
                await this.gnssProviderErrorModalService.dismissModal();
            } catch (err) {
                this.loggerService.log(this.constructor.name,
                    'An error occurred while presenting or dismissing gnss provider error modal', err, LogLevel.Error);
            }
            return;
        }

        try {
            if (this.currentGnssProviderError) {
                await this.gnssProviderErrorModalService.presentModal(this.currentGnssProviderError);
            } else {
                await this.gnssProviderErrorModalService.dismissModal();
            }
        } catch (err) {
            this.loggerService.log(this.constructor.name,
                'An error occurred while presenting or dismissing gnss provider error modal', err, LogLevel.Error);
        }
    }

    /**
     * Initialize german as default language.
     *
     * @private
     */
    private async initLanguage(): Promise<void> {
        this.translateService.use("de");
        this.translateService.setDefaultLang("de");

        // this is to make sure the translation service has loaded the language
        await this.translateService.get('ensureTranslationInitialized').toPromise();
    }
}
