import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

import {environment} from "@environment/environment";

@Injectable({
    providedIn: 'root'
})
export class ConfigureExternalDataSourcesAllowedGuard implements CanActivate {
    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        if (environment.allowConfigurationOfExternalDataSources) {
            return true;
        }
        return this.router.parseUrl('/login');
    }
}
