import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

import {GeoPositionSourceService} from "@services/geo-position/geo-position-source.service";

@Injectable({
    providedIn: 'root'
})
export class GeoPositionSourceSetGuard implements CanActivate {
    constructor(private geoPositionSourceService: GeoPositionSourceService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        if (this.geoPositionSourceService.isGeoPositionSourceSet()) {
            return true;
        }
        return this.router.parseUrl('/configure-external-data-sources');
    }
}
