import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {LoggerService} from "@services/log/logger.service";
import {DasMessagingService} from "@services/data/das-messaging.service";
import {FactualSpeedHistoryService} from "@services/data/factual-speed-history.service";
import {YAxisLocationReferencePointsService} from "@services/data/y-axis-location-reference-points.service";
import {DasOAdviceService} from "@services/data/das-o-advice.service";
import {DriveControlService} from "@services/data/drive-control.service";
import {FarsightRearviewService} from "@services/data/farsight-rearview.service";
import {InfoMessageService} from "@services/data/info-message.service";
import {EbulaPositionControlService} from "@services/data/ebula-position-control.service";
import {TrainInfoService} from "@services/data/train-info.service";
import {ZoomRangeService} from "@services/data/zoom-range.service";

@Injectable({
    providedIn: 'root'
})
export class ClearService {
    constructor(private router: Router,
                private dasOAdviceService: DasOAdviceService,
                private dasMessagingService: DasMessagingService,
                private driveControlService: DriveControlService,
                private ebulaPositionControlService: EbulaPositionControlService,
                private factualSpeedHistoryService: FactualSpeedHistoryService,
                private farsightRearviewService: FarsightRearviewService,
                private yAxisLocationReferencePointsService: YAxisLocationReferencePointsService,
                private infoMessageService: InfoMessageService,
                private trainInfoService: TrainInfoService,
                private zoomRangeService: ZoomRangeService,
                private loggerService: LoggerService) {
        dasMessagingService.getUnscheduledStopEventMessage().subscribe(() => {
            this.loggerService.log(this.constructor.name, 'Clearing advice message.');
            this.dasMessagingService.clearAdviceMessage();
        });
        dasMessagingService.getGpsLostEventMessage().subscribe(() => {
            this.clearDynamicRunDataExceptFarsightRearview();
        });
        dasMessagingService.getUnknownRouteEventMessage().subscribe(() => {
            this.clearDynamicRunDataExceptFarsightRearview();
        });
    }

    /**
     * Clear all data of a run
     */
    public clearAllRunData() {
        this.loggerService.log(this.constructor.name, 'Clearing all run information.');
        this.clearAllRunDataExceptTrainHeadCode();
        this.trainInfoService.clear();
        this.ebulaPositionControlService.reset();
    }

    /**
     * Clear all data of a run except the train head code
     */
    public clearAllRunDataExceptTrainHeadCode() {
        this.loggerService.log(this.constructor.name, 'Clearing all run information except train head code');
        this.clearDynamicRunData();
        this.dasMessagingService.clear();
        this.trainInfoService.clearExceptTrainHeadCode();
        this.infoMessageService.clear();
    }

    /**
     * Clear all data which changes dynamically during a run.
     */
    public clearDynamicRunData() {
        this.clearDynamicRunDataExceptFarsightRearview();
        this.clearFarsightRearviewInformation();
    }

    /**
     * Clear all data which changes dynamically during a run except farsight rearview.
     */
    public clearDynamicRunDataExceptFarsightRearview() {
        this.loggerService.log(this.constructor.name, 'Clearing all dynamic run information besides Farsight Rearview.');
        this.dasMessagingService.clearDynamicRunDataExceptInfoAndErrorMessages();
        this.factualSpeedHistoryService.clear();
        this.yAxisLocationReferencePointsService.clear();
        this.dasOAdviceService.reset();
        this.driveControlService.clear();
        this.zoomRangeService.clear();
    }

    /**
     * Clear all farsight rearview data.
     */
    public clearFarsightRearviewInformation(){
        this.loggerService.log(this.constructor.name, 'Clearing Farsight Rearview information.');
        this.farsightRearviewService.clear();
    }
}
