import {Injectable} from '@angular/core';

import {environment} from "@environment/environment";
import {LoggerService} from "@services/log/logger.service";

/**
 * This service is responsible for providing the endpoint URLs.
 */
@Injectable({
    providedIn: 'root'
})
export class EndpointsService {
    private defaultAuthUrl = environment.authUrl;
    private defaultApplicationUrl = environment.applicationUrl;
    private defaultGpsSimulatorUrl = environment.gpsSimulatorUrl;

    public authUrl;
    public applicationUrl;
    public gpsSimulatorUrl;

    constructor(private loggerService: LoggerService) {
    }

    /**
     * this functions checks whether the endpoints are set.
     * if configuration of external data sources is not allowed, this is always expected to be true.
     */
    public areEndpointsSet(): boolean {
        return !environment.allowConfigurationOfExternalDataSources || (!!this.authUrl && !!this.applicationUrl && !!this.gpsSimulatorUrl);
    }

    public setAuthUrl(url: string): void {
        if (!environment.allowConfigurationOfExternalDataSources) {
            this.loggerService.log(this.constructor.name, 'Changing authUrl is disabled.');
            return;
        }

        this.authUrl = url;
    }

    public getAuthUrl(): string {
        return environment.allowConfigurationOfExternalDataSources && !!this.authUrl ? this.authUrl : this.defaultAuthUrl;
    }

    public setApplicationUrl(url: string): void {
        if (!environment.allowConfigurationOfExternalDataSources) {
            this.loggerService.log(this.constructor.name, 'Changing applicationUrl is disabled.');
            return;
        }

        this.applicationUrl = url;
    }

    public getApplicationUrl(): string {
        return environment.allowConfigurationOfExternalDataSources && !!this.applicationUrl ? this.applicationUrl : this.defaultApplicationUrl;
    }

    public setGpsSimulatorUrl(url: string): void {
        if (!environment.allowConfigurationOfExternalDataSources) {
            this.loggerService.log(this.constructor.name, 'Changing gpsSimulatorUrl is disabled.');
            return;
        }

        this.gpsSimulatorUrl = url;
    }

    public getGpsSimulatorUrl(): string {
        return environment.allowConfigurationOfExternalDataSources && !!this.gpsSimulatorUrl ? this.gpsSimulatorUrl : this.defaultGpsSimulatorUrl;
    }
}
