import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class DarkModeService {

	private darkModeEnabledSubject$ = new BehaviorSubject<boolean>(false);

	constructor() {
	}

	getDarkModeState(): Observable<boolean> {
		return this.darkModeEnabledSubject$.asObservable();
	}

	public toggleDarkMode() {
		const state = this.darkModeEnabledSubject$.getValue();
		this.darkModeEnabledSubject$.next(!state);
	}
}
