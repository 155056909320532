import {DasClientError} from "@knorr-bremse/das-client";

export enum DasClientErrorCode {
    RegistrationFailed = 'RegistrationFailed',
    LocoSelectionFailed = 'LocoSelectionFailed',
    TrainServiceListingFailed = 'TrainServiceListingFailed',
    TrainServiceSelectionFailed = 'TrainServiceSelectionFailed',
    PhysicalParameterSettingFailed = 'PhysicalParameterSettingFailed',
    ContinueUnfinishedRunFailed = 'ContinueUnfinishedRunFailed',
    UnexpectedError = 'UnexpectedError',
    InvalidOperation = 'InvalidOperation',
    Forbidden = 'Forbidden',
    ServiceTimeout = 'ServiceTimeout'
}

export const errorCodeToTextMap = {
    [DasClientErrorCode.RegistrationFailed]: 'Registration failed! Please check your credentials and try again.',
    [DasClientErrorCode.LocoSelectionFailed]: 'Loco selection failed! Please check the entered Loco ID.',
    [DasClientErrorCode.TrainServiceListingFailed]: 'The train ID is not specified or invalid. Please enter a different train ID!',
    [DasClientErrorCode.TrainServiceSelectionFailed]: 'Train service selection failed!',
    [DasClientErrorCode.PhysicalParameterSettingFailed]: 'Physical parameter setting failed!',
    [DasClientErrorCode.ContinueUnfinishedRunFailed]: 'Continue unfinished run failed!',
    [DasClientErrorCode.UnexpectedError]: 'Unexpected error!',
    [DasClientErrorCode.InvalidOperation]: 'Invalid operation!',
    [DasClientErrorCode.Forbidden]: 'Authentication error! A new loco selection or a new registration is required.',
    [DasClientErrorCode.ServiceTimeout]: 'DAS service is not available.'
};

export const translateErrorMessage = (err): string => {
    if (err instanceof DasClientError) {
        if (errorCodeToTextMap.hasOwnProperty(err.errorCode)) {
            return errorCodeToTextMap[err.errorCode];
        }
    }
    return 'An unknown error has occurred. Please try again.';
};
