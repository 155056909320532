import {inject, Injectable} from "@angular/core";
import * as crypto from "crypto";

import {Storage} from "@knorr-bremse/das-client/storage";
import {LoggerService} from "@services/log/logger.service";
import {LogLevel} from "@models/LogLevel";

enum PersistentStorageRequestTypes {
    PersistentStorageGet = 'PersistentStorageGet',
    PersistentStorageSet = 'PersistentStorageSet',
    PersistentStorageDelete = 'PersistentStorageDelete',
    PersistentStorageGetResponse = 'PersistentStorageGetResponse',
    PersistentStorageSetResponse = 'PersistentStorageSetResponse',
    PersistentStorageDeleteResponse = 'PersistentStorageDeleteResponse'
}


// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DasClientStorage extends Storage {
}

@Injectable({
    providedIn: 'root'
})
export class DasClientMemoryStorage implements DasClientStorage {
    private loggerService: LoggerService = inject(LoggerService);

    private map: Map<any, any>;

    constructor() {
        this.loggerService.log(this.constructor.name, `using memory storage`);
        this.map = new Map();
    }

    public async remove(key) {
        this.map.delete(key);
    }

    public async get(key) {
        return this.map.get(key);
    }

    public async set(key, value) {
        this.map.set(key, value);
    }
}

@Injectable({
    providedIn: 'root'
})
export class DasClientPersistentStorage implements DasClientStorage {
    private loggerService: LoggerService = inject(LoggerService);

    private pendingRequests: Map<string, any>;

    constructor() {
        this.loggerService.log(this.constructor.name, `using window:message-based persistent storage`);
        this.pendingRequests = new Map();
        window.addEventListener('message', (event) => this.handleWindowMessage(event));
    }

    private postWindowMessageToParent(requestId, requestType, key, value = undefined) {
        window.parent.postMessage({message: requestType, data: {requestId, key, value}}, '*');
    }

    public async remove(key: string) {
        this.loggerService.log(this.constructor.name, `publishing Window:message for delete ${key}`);
        const requestId = this.createPendingRequest();
        this.postWindowMessageToParent(requestId, PersistentStorageRequestTypes.PersistentStorageDelete, key);
        await this.waitForResponseValue(requestId);
    }

    public async get(key: string) {
        this.loggerService.log(this.constructor.name, `publishing Window:message for get ${key}`);
        const requestId = this.createPendingRequest();
        this.postWindowMessageToParent(requestId, PersistentStorageRequestTypes.PersistentStorageGet, key);
        return await this.waitForResponseValue(requestId);
    }

    public async set(key: string, value: string) {
        this.loggerService.log(this.constructor.name, `publishing Window:message for set ${key}:${value}`);
        const requestId = this.createPendingRequest();
        this.postWindowMessageToParent(requestId, PersistentStorageRequestTypes.PersistentStorageSet, key, value);
        await this.waitForResponseValue(requestId);
    }

    private waitForResponseValue(requestId): Promise<string | null> {
        const timeout = 2;
        const startTime = Date.now() / 1000;

        return new Promise((resolve, reject) => {
            const intervalHandle = setInterval(() => {
                const now = Date.now() / 1000;
                if (now - startTime >= timeout) {
                    clearInterval(intervalHandle);
                    return reject(new Error(`timeout while waiting for response with id ${requestId}`));
                }

                const pendingRequestValue = this.pendingRequests.get(requestId);
                if (pendingRequestValue !== undefined) {
                    clearInterval(intervalHandle);
                    this.loggerService.log(this.constructor.name, `resolving pending request "${requestId}" with value ${pendingRequestValue}`);
                    return resolve(pendingRequestValue);
                }
            }, 100);
        });
    }

    private createPendingRequest() {
        const requestId = crypto.randomBytes(16).toString('hex');
        this.pendingRequests.set(requestId, undefined);
        return requestId;
    }

    private updatePendingRequest(requestId, value) {
        this.pendingRequests.set(requestId, value);
    }

    private handleWindowMessage(message) {
        if (!message.data || !message.data.message || !message.data.data) {
            return;
        }

        const responseType = message.data.message;
        if (responseType !== PersistentStorageRequestTypes.PersistentStorageGetResponse
            && responseType !== PersistentStorageRequestTypes.PersistentStorageSetResponse
            && responseType !== PersistentStorageRequestTypes.PersistentStorageDeleteResponse) {
            return;
        }

        const persistentStorageResponse = message.data.data;
        if (!this.pendingRequests.has(persistentStorageResponse.requestId)) {
            this.loggerService.log(this.constructor.name, `received persistent storage response for unknown request id`, {message}, LogLevel.Error);
            return;
        }

        // eslint-disable-next-line max-len
        this.loggerService.log(this.constructor.name, `received persistent storage response "${responseType}" for id ${persistentStorageResponse.requestId} -> ${persistentStorageResponse.key}:${persistentStorageResponse.value}`);
        this.updatePendingRequest(persistentStorageResponse.requestId, persistentStorageResponse.value);
    }
}
