import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from "rxjs";
import {ZoomRange} from "@models/chartModels";
import {DasMessagingService} from "./das-messaging.service";
import {determineCloseSpeedLimits, determineZoomRanges} from "@util/ZoomHelper";

@Injectable({
    providedIn: 'root'
})

/**
 * This service provides zoom ranges that should be applied by all charts.
 * Zoom Ranges always need to be applied one after the other meaning that you need to handle the first zoom range
 * and then apply the second zoom range to the result and so on.
 */

export class ZoomRangeService {
    // When speedlimits are closer than 200 location units they start to overlap
    private minimalDistanceBetweenSpeedLimits = 200;
    private distanceBetweenSpeedLimitsAfterZoom = 400;
    private overallZoomRanges$ = new BehaviorSubject<ZoomRange[]>([]);

    constructor(private dasMessagingService: DasMessagingService) {
        this.dasMessagingService.getRouteMessage().subscribe(routeMessage => {
            if (routeMessage) {
                const closeSpeedLimits = determineCloseSpeedLimits(routeMessage.speedLimits, this.minimalDistanceBetweenSpeedLimits);
                const overallSpeedRanges = determineZoomRanges(closeSpeedLimits, this.distanceBetweenSpeedLimitsAfterZoom);
                this.overallZoomRanges$.next(overallSpeedRanges);
            }
        });
    }

    /**
     * Clear the y-Axis reference points.
     */
    public clear(): void {
        this.overallZoomRanges$.next([]);
    }

    /**
     * returns all zoom ranges for this run as an observable
     */

    public getOverallZoomRanges(): Observable<ZoomRange[]> {
        return this.overallZoomRanges$.asObservable();
    }
}
