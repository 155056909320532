/**
 * Helper class for all location stuff.
 */
import {LocationElement, LocationMarker, RouteElement} from "@models/routeMessageModels";

export class LocationHelper {

    /**
     * Calculates the km/h value according to the given m/s speed value and restricts the amount of decimals.
     *
     * @param meterPerSecondValue Speed value in m/s.
     * @param decimals amount of numbers that are allowed as decimals.
     *
     * @return number Returns the rounded km/h representation of the submitted m/s value.
     */
    public static convertMetersPerSecondToKilometersPerHour(meterPerSecondValue: number, decimals: number = 1): number {
        const kmPerHour = (meterPerSecondValue / 1000) / (1 / 3600);
        return Math.round(kmPerHour * decimals) / decimals;
    }

    /**
     * Filters the given location elements by the range provided via from and to.
     * The function will return the array containing the first point outside the range as well so that we get all connections between points within a range.
     *
     * @param elements The elements to filter.
     * @param from The start of the range.
     * @param to The end of the range.
     * @return [] Returns an array of all elements that are required so that we get all connecting lines within the given range.
     */
    public static filterSpeedAdviceLocations(elements: LocationElement[], from: number, to: number): LocationElement[] {
        const filteredElements: LocationElement[] = [];
        let lastUsedIndex;
        for (const [index, item] of elements.entries()) {
            if (item.location >= from && item.location <= to) {
                filteredElements.push(item);
                lastUsedIndex = index;
            }
        }
        if (lastUsedIndex < elements.length - 1) {
            filteredElements.push(elements[lastUsedIndex + 1]);
        }
        return filteredElements;
    }


    /**
     * Filters the needed elements from inside the submitted range.
     *
     * @param elements The elements to filter. Should be an array of RouteElements, locationmarkers or anything else with a location-property.
     * @param from The start of the range.
     * @param to The end of the range.
     *
     * @return [] Returns an array of all elements inside the given range.
     */
    public static filterLocations(elements: RouteElement[], from: number, to: number): RouteElement[]
    public static filterLocations(elements: LocationMarker[], from: number, to: number): LocationMarker[]
    public static filterLocations(elements: any, from: number, to: number): any {
        const filteredElements = elements
            .filter(element => element.hasOwnProperty("location") ? element.location >= from && element.location <= to : false);
        // TODO: check if this can crash. If 'location' does not exist, the sort function should not be called because of an empty array.
        return filteredElements.sort((a, b) => (a.location - b.location));
    }

    /**
     * Find the nearest element to a given Location.
     *
     * @param elements The elements in which to search. Should be an array of AdvisedRunProfileElements or RouteElements that is sorted by location (ascending).
     * @param referenceLocation The location near which a location should be found
     *
     * @return any return the element with the nearest location
     */
    public static findNearestLocation(elements: { location: number }[], referenceLocation: number): RouteElement {
        let nearestLocation;
        let nearestLocationDistance;
        for (const element of elements) {
            if (element.hasOwnProperty("location")) {
                const distance = Math.abs(element.location - referenceLocation);
                if (nearestLocationDistance === undefined || distance < nearestLocationDistance) {
                    nearestLocationDistance = distance;
                    nearestLocation = element;
                } else if (distance > nearestLocationDistance) {
                    break;
                }
            }
        }
        return nearestLocation;
    }

    /**
     * calculates the closest position on the chart that can be displays
     *
     * @param location The location of the speed limit.
     * @param lowerBound The start location for the range.
     * @param upperBound The end location of the range.*
     * @return number Returns the closest location that can be displayed give a location.
     */
    public static getClosestPositionCurrentlyDisplayed(location: number, lowerBound: number, upperBound: number) {
        if (location < lowerBound) {
            return lowerBound;
        }
        if (location > upperBound) {
            return upperBound;
        }
        return location;
    }

    /**
     * returns true if the number is within the given lower bound and upper bound
     *
     * @param location The location of the speed limit.
     * @param lowerBound The start location for the range.
     * @param upperBound The end location of the range.
     * @return boolean Returns the closest location that can be displayed give a location.
     */
    public static isLocationInRange(location: number, lowerBound: number, upperBound: number) {
        return lowerBound < location && location < upperBound;
    }
}

