import {inject, Injectable} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {
    GnssProviderErrorModalComponent
} from "@components/gnss-provider-error-modal/gnss-provider-error-modal.component";

@Injectable({
	providedIn: 'root'
})
export class GnssProviderErrorModalService {
    private modalController = inject(ModalController);
    private modal: HTMLIonModalElement;

	constructor() {
	}

    async dismissModal() {
        if (this.modal) {
            await this.modal.dismiss();
            this.modal = null;
        }
    }

    async presentModal(gnssProviderError: string) {
        if (this.modal) {
            await this.dismissModal();
        }

        this.modal = await this.modalController.create({
            component: GnssProviderErrorModalComponent,
            cssClass: 'small',
            componentProps: {
                gnssProviderError,
            },
            backdropDismiss: false
        });
        await this.modal.present();
    }
}
