import {Injectable} from '@angular/core';
import {DasMessagingService} from "./das-messaging.service";
import {BehaviorSubject, Observable} from "rxjs";
import {LoggerService} from "@services/log/logger.service";
import {InformationMessageText, InformationMessageType} from "@models/infoMessagesModels";

@Injectable({
    providedIn: 'root'
})
export class InfoMessageService {
    private currentInfoMessage$: BehaviorSubject<string> = new BehaviorSubject<string>("");
    private notability$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private dasMessagingService: DasMessagingService, private loggerService: LoggerService) {
        this.dasMessagingService.getInfoMessage().subscribe((infoMessageType: InformationMessageType) => {
            this.updateInfoMessage(infoMessageType);
        });
    }


    private updateInfoMessage(infoMessageType: InformationMessageType) {
        this.loggerService.log(this.constructor.name, "Info message changed", infoMessageType);
        if (infoMessageType === null) {
            this.clear();
            return;
        }
        if (infoMessageType in InformationMessageText) {
            this.currentInfoMessage$.next(InformationMessageText[infoMessageType]);
            if (!this.notability$.value) {
                this.notability$.next(true);
            }
        } else {
            this.loggerService.log(this.constructor.name, "Seen Infomessage with unknown type", infoMessageType);
        }

    }

    public getInfoMessageNotability(): Observable<boolean> {
        return this.notability$.asObservable();
    }

    public getInfoMessageText(): Observable<string> {
        return this.currentInfoMessage$.asObservable();
    }

    public clear() {
        this.loggerService.log(this.constructor.name, "Setting notability to false and clearing message");
        this.notability$.next(false);
        this.currentInfoMessage$.next("");
    }

}
